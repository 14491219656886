import React from 'react'
import axios from 'axios'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import AddAlertIcon from '@mui/icons-material/AddAlert';
import Button from '@mui/material/Button';
import {green, red} from '@mui/material/colors';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import withStyles from '@mui/styles/withStyles';
// core components
import GridItem from 'components/Grid/GridItem.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'
import Card from 'components/Card/Card.jsx'
import CardHeader from 'components/Card/CardHeader.jsx'
import CardBody from 'components/Card/CardBody.jsx'
import Snackbar from 'components/Snackbar/Snackbar.jsx'
import {w3cwebsocket as W3CWebSocket} from 'websocket'
import { DataGrid, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid'

const {REACT_APP_SERVER_URL, REACT_APP_WS_URL} = process.env
const httpUrl = `${REACT_APP_SERVER_URL}`
const wsUrl = `${REACT_APP_WS_URL}`
const styles = {
    cardCategoryWhite: {
        '&,& a,& a:hover,& a:focus': {
            color: 'rgba(255,255,255,.62)',
            margin: '0',
            fontSize: '14px',
            marginTop: '0',
            marginBottom: '0',
        },
        '& a,& a:hover,& a:focus': {
            color: '#FFFFFF',
        },
    },
    cardTitleWhite: {
        color: '#FFFFFF',
        marginTop: '0px',
        minHeight: 'auto',
        fontWeight: '300',
        fontFamily: '\'Roboto\', \'Helvetica\', \'Arial\', sans-serif',
        marginBottom: '3px',
        textDecoration: 'none',
        '& small': {
            color: '#777',
            fontSize: '65%',
            fontWeight: '400',
            lineHeight: '1',
        },
    },
}

class DeviceList extends React.Component {

  constructor(props) {
    super(props)
    console.log('props',props)
    this.state = {
      classes: props.classes,
      tabData: [],
      history: props.history,
      deviceId: '',
      delOpen: false,
      modOpen: false,
      tc: false,
      modifiedName: '',
      userId : '00030001',
      modifiedFrequency: 20000,
      tce: false,
     }
    }

    seriesMap = {}
    labelMap = {}
    ws = {}
    tmoutfn = {}

    componentWillUnmount() {
        clearInterval(this.tmoutfn)
        this.ws.close()
    }

    refreshData(userId) {
        userId = this.state.userId
        axios.post(`${httpUrl}/data/getDataSnapshot`, {
                userId,
            },
            {
                withCredentials: true,
            })
            .then(res => {

                this.setState({tabData: res.data})
            }).catch((error)=>{
            let message
            const { response } = error
            if (response) {
                const { data } = response
                if (data) message = data.message
            }
            if(!message) message = error.message
            console.log('error:', message)
            //console.log('message',message)
            this.props.showSnackBar(message, AddAlertIcon, "error")
            this.props.onClose(true)
        })

    };

    connectStream() {
        this.ws = new W3CWebSocket(`${wsUrl}/livestream`)
        var self = this
        this.ws.onopen = () => {
            // on connecting, send userid data interested in
            console.log('connected')
            this.tmoutfn = setInterval(function () {
                self.ws.send(JSON.stringify({'keepAlive': true}))
            }, 10000)
        }

        this.ws.onmessage = evt => {
            // listen to data sent from the websocket server
            const devData = JSON.parse(evt.data)
            const payload = devData.eventData
            if (devData.eventType === 'DEVICE_UPDATE') {
                console.log('event received')
                var curData = self.state.tabData
                for (var i = 0; i < curData.length; i++) {
                    if (curData[i].deviceId === payload.deviceId) {
                        curData[i].deviceName = payload.deviceName
                        curData[i].wifiStrength = payload.wifiStrength
                        curData[i].batteryLevel = payload.batteryLevel
                        curData[i].connectionState = payload.connectionState
                        curData[i].updatedAt = payload.lastAlive
                        curData[i].pollingFrequency = payload.pollingFrequency
                        curData[i].maxOfflineRecords = payload.maxOfflineRecords
                        self.setState({tabData: curData})
                    }
                }
            } else if (devData.eventType === 'DEVICE_DELETE') {
                console.log('event received')
                const curData = self.state.tabData
                for (let i = 0; i < curData.length; i++) {
                    if (curData[i].deviceId === payload.deviceId) {
                        curData.splice(i, 1)
                        self.setState({tabData: curData})
                    }
                }
            } else if (devData.eventType === 'DEVICE_CREATE') {
                console.log('event received')
                const curData = self.state.tabData
                curData.push(payload)
                self.setState({tabData: curData})
            }
        }

        this.ws.onclose = () => {
            clearInterval(this.tmoutfn)
        }
    }

    async componentDidMount() {
        this.refreshData(this.state.userId)
        this.connectStream()
    };

    async modifyData(deviceId, modifiedData) {
        let registerRequest
        console.log(' modifiedData, deviceId ', modifiedData, deviceId)
        try {
            registerRequest = await axios.post(
                `${httpUrl}/data/modDevice`,
                {userId: this.state.userId, modifiedData, deviceId},
                {
                    withCredentials: true,
                },
            )
        } catch ({response}) {
            registerRequest = response
        }
        const {data: registerRequestData} = registerRequest
        if (!registerRequestData.updated) {
            this.setState({tce: true})
        } else {
            this.setState({tc: true})
            this.refreshData()
        }
        this.alertTimeout = setTimeout(
            function () {
                this.setState({tce: false, tc: false})
            }.bind(this),
            6000,
        )
    };

    async deleteData(devId) {
        let registerRequest
        try {
            registerRequest = await axios.post(
                `${httpUrl}/data/delDevice`,
                {
                    deviceId: devId,
                    userId: this.state.userId,
                },
                {
                    withCredentials: true,
                },
            )
        } catch ({response}) {
            registerRequest = response
        }
        const {data: registerRequestData} = registerRequest

        if (registerRequestData.deleted === 0) {
            this.setState({tce: true})
        } else {
            this.setState({tc: true})
            this.refreshData()
        }

        this.alertTimeout = setTimeout(
            function () {
                this.setState({tce: false, tc: false})
            }.bind(this),
            6000,
        )
    };

    handleDelOpen = () => {
        this.setState({delOpen: true})
    }

    handleDelClose = () => {
        this.setState({delOpen: false})
    }

    handleDelOk = () => {
        this.setState({delOpen: false})
        this.deleteData(this.state.deviceId)

    }
    handleModOpen = () => {
        this.setState({modOpen: true})
    }

    handleModClose = () => {
        this.setState({modOpen: false})
    }

    handleModOk = () => {
        this.setState({modOpen: false})
        this.modifyData(this.state.deviceId, {
            deviceId: this.state.deviceId,
            deviceName: this.state.modifiedName,
            pollingFrequency: this.state.modifiedFrequency,
        })
        console.log('mods are', this.state.modifiedName, this.state.modifiedFrequency)

    }
    handleNameChange = (e) => {
        this.setState({modifiedName: e.target.value})
    }
    handleUserIdChange = (e) => {
        this.setState({userId: e.target.value})
    }
    handleFrequencyChange = (e) => {
        this.setState({modifiedFrequency: e.target.value})
    }
  
  CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }


    render() {
    let columns = [
        { headerName: 'Name',               field: 'deviceName'       , width: 150},
      { headerName: 'Type',               field: 'deviceType'       , width: 150},
      { headerName: 'Id',                 field: 'deviceId'         , width: 250},
      { headerName: 'WifiStrength',       field: 'wifiStrength'     , width: 100},
      { headerName: 'Local Ip',           field: 'localIp'          , width: 100},
      { headerName: 'Battery Level',      field: 'batteryLevel'     , width: 100},
      { headerName: 'Polling Frequency',  field: 'pollingFrequency' , width: 140},
      { headerName: 'Max Offline records',field: 'maxOfflineRecords', width: 140},
      {
        headerName: 'last Updated At', field: 'lastAlive',width: 250,
        renderCell: (rowData) => <span>
          {new Date(rowData.row.timestampLastAlive * 1000).toString()}
        </span>,
      },
      {
        headerName: 'Connection Status', field: 'connectionState', type: 'boolean',width: 160,
        renderCell: rowData => (rowData.row.connectionState === 'false' || !rowData.row.connectionState) ?
            <FiberManualRecordIcon style={{ color: red[500] }} /> : <FiberManualRecordIcon
                style={{ color: green[500] }} />,
      },{
        headerName: 'Action', field: 'action', type: 'action', width: 100,
        renderCell:(params)=>{
          return <>
            <IconButton onClick={console.log('Open')} size="large">
              <EditIcon
                  onClick={(event) => this.setState({
                    deviceId: params.row.deviceId,
                    modOpen: true,
                  })
                  }/>
            </IconButton>
            <IconButton size="large">
              <DeleteIcon
                  onClick={(event)=>
                      this.setState({deviceId: params.row.deviceId,delOpen: true,})
                  }/>
            </IconButton>
          </>;

        }
      }]
    console.log('this.state.tabData',this.state.tabData)
    let rows = this.state.tabData.map((row,index)=>{
      let row_ =  {
        id:index,deviceName:row.deviceName,deviceType:row.deviceType,
        deviceId:row.deviceId,wifiStrength:row.wifiStrength,localIp:row.localIp,
        batteryLevel:row.batteryLevel,pollingFrequency:row.pollingFrequency,
        maxOfflineRecords:row.maxOfflineRecords,lastAlive:row.updatedAt,connectionState: row.connectionState,
        timestampLastAlive: row.lastAlive}
      return row_
    })

    return (
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Snackbar
                place='tc'
                color='info'
                icon={AddAlertIcon}
                message='Device Updated Successfully!!'
                open={this.state.tc}
                closeNotification={() => this.setState({ tc: false })}
                close
            />
            <Snackbar
                place='tc'
                color='info'
                icon={AddAlertIcon}
                message='Device Update Failed!!'
                open={this.state.tce}
                closeNotification={() => this.setState({ tce: false })}
                close
            />
            <Dialog
                open={this.state.delOpen}
                onClose={this.handleDelClose}
                aria-labelledby='alert-dialog-title'
                aria-describedby='alert-dialog-description'>
              <DialogTitle id='alert-dialog-title'>{'Sensor Device Removal'}</DialogTitle>
              <DialogContent>
                <DialogContentText id='alert-dialog-description'>
                  Are you sure to delete the Sensor Device ?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={this.handleDelClose} color='primary'>
                  Cancel
                </Button>
                <Button onClick={this.handleDelOk} color='primary'>
                  OK
                </Button>

              </DialogActions>
            </Dialog>
            <Dialog
                open={this.state.modOpen}
                onClose={this.handleModClose}
                aria-labelledby='alert-dialog-title'
                aria-describedby='alert-dialog-description'>
              <DialogTitle id='alert-dialog-title'>{'Sensor Config Edit'}</DialogTitle>
              <DialogContent>
                <DialogContentText id='alert-dialog-description'>
                  Update Following fields
                </DialogContentText>
                <TextField value={this.state.modifiedName} onChange={this.handleNameChange}
                           label='Device Name' variant='outlined' />
                <TextField  value={this.state.modifiedFrequency} onChange={this.handleFrequencyChange}
                            label='Polling Frequency' variant='outlined' />
              </DialogContent>
              <DialogActions>
                <Button onClick={this.handleModClose} color='primary'>
                  Cancel
                </Button>
                <Button onClick={this.handleModOk} color='primary'>
                  OK
                </Button>

              </DialogActions>
            </Dialog>
            <Card>
              <CardHeader color='success'>
                <h4 className={this.state.classes.cardTitleWhite}>IAQ Sensor Devices</h4>
                <p className={this.state.classes.cardCategoryWhite}>
                  View IAQ Sensors
                </p>
              </CardHeader>
              <CardBody>
                <TextField style={{ padding: '10px' }} value={this.state.userId} onChange={this.handleUserIdChange}
                           label='UserId' variant='outlined' />
                <Button onClick={ () => this.refreshData(this.state.userId)} color='primary' variant='outlined'>
                  Refresh
                </Button>
                <DataGrid
                    sx={{width:'100%',height:500,textAlign:'center'}}
                    columns={columns}
                    rows={rows}
                    components={{
                      Toolbar: this.CustomToolbar, // Render the GridToolbar component
                    }}
                >
                </DataGrid>

              </CardBody>
            </Card>
          </GridItem>

        </GridContainer>
    )
  }

}

export default withStyles(styles)(DeviceList)
