import React from 'react'
import axios from 'axios'
//@mui5
import { Chip } from '@mui/material'
import GridItem from 'components/Grid/GridItem.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'
import Card from 'components/Card/Card.jsx'
import CardHeader from 'components/Card/CardHeader.jsx'
import CardBody from 'components/Card/CardBody.jsx'
import '../../style/DeviceStyle/RuleList.jsx.css'
import CreateRuleForm from './CreateRuleForm'
import ModificationRule from './ModificationRule'
import DeleteRuleForm from './DeleteRuleForm'
import Snackbar from 'components/Snackbar/Snackbar.jsx'
import { DataGrid } from '@mui/x-data-grid'
import IconButton from '@mui/material/IconButton'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import Button from '@mui/material/Button'
import withStyles from '@mui/styles/withStyles'
import { getDisplayDays, getLocalTimeDays } from './utils'
import TextField from '@mui/material/TextField'
import _ from 'lodash'

const { REACT_APP_SERVER_URL } = process.env
const httpUrl = `${REACT_APP_SERVER_URL}`

const styles = {
  FormControl: {
    width: 400
  },
  cardCategoryWhite: {
    '&,& a,& a:hover,& a:focus': {
      color: 'rgba(255,255,255,.62)', margin: '0', fontSize: '14px', marginTop: '0', marginBottom: '0',
    }, '& a,& a:hover,& a:focus': {
      color: '#FFFFFF',
    },
  }, cardTitleWhite: {
    color: '#FFFFFF',
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: '\'Roboto\', \'Helvetica\', \'Arial\', sans-serif',
    marginBottom: '3px',
    textDecoration: 'none',
    '& small': {
      color: '#777', fontSize: '65%', fontWeight: '400', lineHeight: '1',
    },
  }, buttonBgColor: {
    color: '#cccccc'
  },
}

class RuleList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      classes: props.classes,
      tabData: [],
      history: props.history,
      deviceId: '',
      delOpen: false,
      modOpen: false,
      tc: false,
      poiId: '',
      userId: 'boschuser',
      storeId: '',
      endpointId: '',
      time: new Date(),
      days: '',
      period: '',
      localDays: [],
      rowDtails: [],
      buttonColor: 'rgb(' + (255) + ',' + (0) + ',' + (0) + ')',
      tce: false,
      weekButtons: this.weekButtons,
      snackBar: {
        message: null,
        status: false,
        icon: null,
        color: null
      },
      reRender: false,
    }
    this.editRuleIconClick = this.editRuleIconClick.bind(this)
    this.deleteRuleIconClick = this.deleteRuleIconClick.bind(this)
  }
  
  showSnackBar = (message, icon, color) => {
    console.log('color', color)
    this.setState({
      ...this.state, snackBar: {
        message: message,
        status: true,
        icon: icon,
        color: color
      }
    })
    setTimeout(() => {
      this.setState({
        snackBar: {
          status: false
        }
      })
    }, 6000)
  }
  
  handleModClose = (done) => {
    this.setState({ modOpen: false })
    if(done) this.refreshData(this.state.userId)
  }
  
  handleCreClose = (done) => {
    this.setState({ creOpen: false })
    if(done) this.refreshData(this.state.userId)
  }
  
  handleDelClose = (done) => {
    this.setState({ delOpen: false })
    if(done) this.refreshData(this.state.userId)
  }
  
  handleCreOpen = (e) => {
    this.setState({ creOpen: true })
  }
  
  refreshData(userId) {
    userId = this.state.userId
    console.log('user ID', userId)
    axios.get(`${httpUrl}/data/schedulerule/${userId}`)
      .then(res => {
        const rules = res.data
        console.log('get rule response: ', rules)
        const updatedRules = rules.map((rule) => {
          const { time: localTime, days: localDays } = getLocalTimeDays({ time: rule.time, days: rule.days })
          const updatedRule = {
            ...rule, time: localTime, days: localDays
          }
          updatedRule.days = getDisplayDays(updatedRule.days)
          return updatedRule
        })
        this.setState({ tabData: updatedRules })
      }).catch((error) => {
      console.log('Bosch User Not Get: ', error)
    })
  };
  
  reRender() {
    console.log('rerender')
    this.setState({ reRender: true })
  }
  
  handleUserIdChange = (e) => {
    this.setState({ userId: e.target.value })
  }
  
  editRuleIconClick(e, rowData) {
    const fullRowData = {
      active: rowData.active,
      days: rowData.days,
      endpointId: rowData.endpointId,
      period: rowData.period,
      poiId: rowData.poiId,
      repeat: rowData.repeat,
      ruleId: rowData.ruleId,
      state: rowData.state,
      storeId: rowData.storeId,
      time: rowData.time,
      userId: rowData.userId,
    }
    this.setState({ rowDtails: fullRowData, modOpen: true })
  }
  
  deleteRuleIconClick(e, rowData) {
    const fullRowData = {
      storeId: rowData.storeId,
      userId: rowData.userId,
      poiId: rowData.poiId,
      endpointId: rowData.endpointId,
      ruleId: rowData.ruleId
    }
    this.setState({ rowDtails: fullRowData, delOpen: true })
  }
  
  async componentDidMount() {
    this.refreshData(this.state.userId)
  };
  
  render() {
    let columns = [
      { headerName: 'User ID', field: 'userId', width: 100 },
      { headerName: 'Store Id', field: 'storeId', width: 80 },
      { headerName: 'Poi Id', field: 'poiId', width: 70 },
      { headerName: 'Endpoint Id', field: 'endpointId', width: 150 },
      { headerName: 'Endpoint Name', field: 'endpointName', width: 150 },
      { headerName: 'Time', field: 'time', width: 80 },
      { headerName: 'Period', field: 'period', width: 80 },
      {
        headerName: 'Days', field: 'days', width: 350,
        renderCell: (cellRow) => {
          return (<div className="days">
            {cellRow.row.days.map((day) => (<Chip label={day} color="primary" size="medium"
                                                  variant="outlined"/>))}
          </div>)
        }
      },
      { headerName: 'Repeat', field: 'repeat', width: 70 },
      {
        headerName: 'Action', field: 'state', width: 70,
        renderCell: (cellRow) => {
          const actionState = cellRow.row.state
          const actionOnOff = actionState == 1 ?
            'on'
            : _.size(actionState) > 1 ?
              actionState
              : 'off'
          return (<div>{actionOnOff}</div>)
          
        }
      },
      {
        headerName: 'Status', field: 'active', width: 70,
        renderCell: (cellRow) => {
          const active = cellRow.row.active
          const activeTrueFalse = active == true ? 'active' : 'inactive'
          return (<div>{activeTrueFalse}</div>)
        }
      },
      {
        headerName: 'Actions', field: 'actions', type: 'action', width: 100,
        renderCell: (params) => {
          return <>
            <IconButton
              onClick={(event) => this.editRuleIconClick(event, params.row)}
              size="large">
              <EditIcon/>
            </IconButton>
            <IconButton
              onClick={(event) => {
                this.deleteRuleIconClick(event, params.row)
              }}
              size="large">
              <DeleteIcon/>
            </IconButton>
          </>
        }
      }]
    let rows = this.state.tabData.map((param, index) => {
      let _row = {
        id: index,
        userId: param.userId,
        storeId: param.storeId,
        poiId: param.poiId,
        endpointId: param.endpointId,
        endpointName: param.endpointId.split('.')[1],
        time: param.time,
        period: param.period,
        days: param.days,
        repeat: param.repeat,
        state: param.state,
        active: param.active,
        actions: 'yes',
        ruleId: param.ruleId,
      }
      return _row
    })
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Snackbar
            place="tc"
            icon={this.state.snackBar.icon}
            message={this.state.snackBar.message}
            open={this.state.snackBar.status}
            color={this.state.snackBar.color}
            closeNotification={() => this.setState({ snackBar: { status: false } })}
            close
          />
          {
            this.state.creOpen ?
              <CreateRuleForm showSnackBar={this.showSnackBar} classes={this.state.classes}
                              creOpen={this.state.creOpen}
                              onClose={this.handleCreClose}></CreateRuleForm> : null
          }
          {
            this.state.modOpen ?
              <ModificationRule showSnackBar={this.showSnackBar} rowDtails={this.state.rowDtails}
                                modOpen={this.state.modOpen}
                                onClose={this.handleModClose}
                                reRender={this.reRender}></ModificationRule> : null
          }
          {
            this.state.delOpen ?
              <DeleteRuleForm showSnackBar={this.showSnackBar} rowDtails={this.state.rowDtails}
                              delOpen={this.state.delOpen}
                              onClose={this.handleDelClose}></DeleteRuleForm> : null
          }
          <Card>
            <CardHeader color="success">
              <h4 className={this.state.classes.cardTitleWhite}>
                Devices Rule
              </h4>
              <p className={this.state.classes.cardCategoryWhite}>
                View Rule
              </p>
            </CardHeader>
            <CardBody>
              <div className="newRuleList">
                <Button
                  onClick={this.handleCreOpen}
                  color="primary"
                  variant="contained">
                  Create Rule
                </Button>
                <TextField style={{ marginLeft: '50px', padding: '10px' }} value={this.state.userId}
                           onChange={this.handleUserIdChange}
                           label="UserId" variant="outlined"/>
                <Button onClick={() => this.refreshData(this.state.userId)} color="primary"
                        variant="outlined">
                  Refresh
                </Button>
              </div>
              <DataGrid
                sx={{ width: '100%', height: 400, textAlign: 'center' }}
                columns={columns}
                rows={rows}
              >
              </DataGrid>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>)
  }
}

export default withStyles(styles)(RuleList)
