import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';

// core components
import Admin from "layouts/Admin.jsx";

import "./assets/css/material-dashboard-react.css?v=1.6.0";
import {green} from "@mui/material/colors";
import {DashboardTheme} from "./theme/DashboardTheme";

const hist = createBrowserHistory();



ReactDOM.render(
    <ThemeProvider theme={DashboardTheme}>
      <Router history={hist}>
        <Switch>
          <Route path="/admin" component={Admin} />
          <Redirect from="/" to="/admin/devices" />
        </Switch>
      </Router>
    </ThemeProvider>,
  document.getElementById("root")
);
