import React from 'react'
import axios from 'axios'
import _ from 'lodash'
//@mui component
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { TimePicker } from '@mui/x-date-pickers/TimePicker'
import {
  Box,
  Chip,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from '@mui/material'
import Switch from '@mui/material/Switch'
import Divider from '@mui/material/Divider'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import ButtonGroup from '@mui/material/ButtonGroup'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import AddAlertIcon from '@mui/icons-material/AddAlert'

const { REACT_APP_SERVER_URL } = process.env
const httpUrl = `${REACT_APP_SERVER_URL}`

//let isIrb = true

class CreateRuleForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      classes: props.classes,
      deviceId: '',
      delOpen: false,
      creOpen: props.creOpen,
      tc: false,
      poiId: '',
      userId: '',
      storeId: '',
      endpointId: '',
      time: new Date(),
      days: '',
      period: '',
      repeat: true,
      action: '1',
      localDays: [],
      apiEndpoinId: [],
      weekButtons: this.weekButtons,
      buttonColor: 'rgb(' + (76) + ',' + (175) + ',' + (80) + ')',
      buttonBackgorunThem: '#616161',
      disabled: true,
      deviceData: [],
      dropdownOne: [],
      isIrb: false,
      selectedIRB: '',
    }
    
  }
  
  componentDidMount() {
    const dropdownData = ['PWR_ON', 'PWR_OFF', 'TEMP_18', 'TEMP_19', 'TEMP_20', 'TEMP_21', 'TEMP_22', 'TEMP_23',
      'TEMP_24', 'TEMP_25', 'TEMP_26', 'TEMP_27', 'TEMP_28', 'FAN_1', 'FAN_2', 'FAN_3', 'FAN_4', 'FAN_5']
    this.setState({ dropdownOne: dropdownData })
  }
  
  handleCreOk = () => {
    this.setState(({ creOpen: false }))
  }
  
  handleCraeteRepeat = (e) => {
    this.setState({ repeat: false })
  }
  
  handleCreateAction = () => {
    this.setState({ action: '0' })
  }
  
  endpointApiCall = () => {
    const userId = this.state.userId
    const storeId = this.state.storeId
    const poiId = this.state.poiId
    if(userId.length > 0 && storeId.length > 0 && poiId.length > 0) {
      axios.post(`${httpUrl}/data/getEndpointsSnapshot`,
        {
          userId: userId,
          storeId: storeId,
          poiId: poiId,
          allControls: true,
        },
        {
          withCredentials: true,
        })
        .then((res) => {
          console.log('endpointId Found: ', res)
          this.setState({ apiEndpoinId: res.data })
        })
        .catch((err) => {
          console.log('endpointId Not Found: ', err)
        })
    }
    
  }
  
  checkIRB = () => {
    this.state.apiEndpoinId.map((EndpointId) => {
      const endId = _.first(_.first(this.state.endpointId).split('.'))
      let deviceItem = _.find(this.state.deviceData, { deviceType: 'IRB', deviceId: endId }) //find IRB then match deviceType or endpointId
      if(!_.isEmpty(deviceItem)) {
        this.setState({ selectedIRB: EndpointId.state })
      }
      _.isEmpty(deviceItem) ? this.setState({ isIrb: false }) : this.setState({ isIrb: true })
    })
  }
  
  deviceDataType(userId) {
    axios.post(`${httpUrl}/data/getDataSnapshot`, {
        userId,
        allControls: true,
      },
      {
        withCredentials: true,
      })
      .then(res => {
        console.log('deviceData', res.data)
        this.setState({ deviceData: res.data })
        this.checkIRB()
      }).catch((error) => {
      let message
      const { response } = error
      if(response) {
        const { data } = response
        if(data) message = data.message
      }
      if(!message) message = error.message
      this.props.showSnackBar(message, AddAlertIcon, 'error')
      this.props.onClose(true)
    })
  }
  
  handleCreateUserIdChange = (uId) => {
    this.setState({ userId: uId.target.value })
    setTimeout(() => {
      this.endpointApiCall()
    }, 1000)
    
  }
  
  handleCreateStoreIdChange = (sId) => {
    this.setState({ storeId: sId.target.value })
    setTimeout(() => {
      this.endpointApiCall()
    }, 1000)
  }
  
  handleCreatePoiIdChange = (pId) => {
    this.setState({ poiId: pId.target.value })
    setTimeout(() => {
      this.endpointApiCall()
    }, 1000)
  }
  
  handleCreateEndpointIdChange = (eId) => {
    const {
      target: { value },
    } = eId
    this.setState({ endpointId: typeof value === 'string' ? value.split(',') : value })
    const endpoint = value.split(',').join(' ')
    this.Buttondisable(endpoint)
    this.deviceDataType(this.state.userId)
  }
  
  Buttondisable(endpoint) {
    //this block use for unable && disabled ok button
    console.log(endpoint)
    const userId = this.state.userId
    const storeId = this.state.storeId
    const poiId = this.state.poiId
    if(userId && storeId && poiId && endpoint !== undefined) {
      this.setState({ disabled: false })
    } else {
      this.props.showSnackBar('Fill The Form', AddAlertIcon, 'warning')
    }
  }
  
  endpointApiCall() {
    axios.post(`${httpUrl}/data/getEndpointsSnapshot`,
      {
        userId: this.state.userId,
        storeId: this.state.storeId,
        poiId: this.state.poiId,
        allControls: true,
      },
      {
        withCredentials: true,
      })
      .then((res) => {
        console.log('endpointId Found22: ', res)
        this.setState({ apiEndpoinId: res.data })
      })
      .catch((err) => {
        console.log('endpointId Not Found: ', err)
      })
  }
  
  handleCreateTimeChange = (time) => {
    this.setState({ time: time })
  }
  
  handleCreateLocalDaysChange = (lDays) => {
    const index = this.state.weekButtons.findIndex(item => lDays === item.label)
    this.state.weekButtons[index].status = 1
    const localDays = this.state.weekButtons
      .reduce((newArray, item) => (item.status ? [...newArray, item.label] : newArray), [])
    this.setState({ localDays: localDays, weekButtons: this.state.weekButtons })
    /*DAYS CONVERSION 1010011 START*/
    const targetValue = localDays
    const targetDays = [{
      DaysName: 'Sun',
      status: 0,
    }, {
      DaysName: 'Mon',
      status: 0,
    }, {
      DaysName: 'Tue',
      status: 0,
    }, {
      DaysName: 'Wed',
      status: 0,
    }, {
      DaysName: 'Thu',
      status: 0,
    }, {
      DaysName: 'Fri',
      status: 0,
    }, {
      DaysName: 'Sat',
      status: 0,
    }]
    const weeksDaysNumber = []
    targetValue.forEach((selectedWeek) => {
      targetDays.forEach((value, index) => {
        if(value.DaysName === selectedWeek) {
          value.status = 1
        }
      })
    })
    targetDays.forEach((statusValue) => {
      weeksDaysNumber.push(statusValue.status)
    })
    const localDaysNumber = weeksDaysNumber.join('')
    this.setState({ localDaysNumber: weeksDaysNumber.join('') })
    /*DAYS CONVERSION 1010011 END*/
  }
  
  handleCreatePeriodChange = (peId) => {
    this.setState({ period: peId.target.value })
  }
  
  //this function handleCreateDays use for based on time days conversion
  
  leftShift(str, n) {
    return str.substring(n) + str.substring(0, n)
  }
  
  rightShift(str, n) {
    let l = str.length - n
    return this.leftShift(str, l)
  }
  
  getUTCTimeDays = ({ time, days }) => {
    let utcDays = days
    const hour = Number(_.chain(time).split(':').head().value())
    const min = Number(_.chain(time).split(':').last().value())
    const date = new Date()
    const tzOffset = date.getTimezoneOffset()
    const localDay = date.getDay()
    date.setHours(hour)
    date.setMinutes(min)
    const utcDay = date.getUTCDay()
    const utcHour = date.getUTCHours()
    const utcMinutes = date.getUTCMinutes()
    let utcTime = _.padStart(`${utcHour}`, 2, '0') + ':' + _.padStart(`${utcMinutes}`, 2, '0')
    if((localDay !== utcDay) && (tzOffset > 0)) utcDays = this.rightShift(days, 1)
    if((localDay !== utcDay) && (tzOffset < 0)) utcDays = this.leftShift(days, 1)
    return { time: utcTime, days: utcDays }
  }
  
  handleCreateSubmit = (e) => {
    //console.log('submit', this.state.isIrb)
    e.preventDefault()
    const LocalTime = this.state.time
    const hour = LocalTime.$H
    const minute = LocalTime.$m
    const hh = hour < 10 ? `0${hour}` : hour
    const mm = minute < 10 ? `0${minute}` : minute
    let hrMin = `${hh}:${mm}`
    //default time add send to server
    if(hrMin == 'undefined:undefined') {
      const defaultHour = new Date().getHours()
      const defaultminute = new Date().getMinutes()
      const defaulthh = defaultHour < 10 ? `0${defaultHour}` : defaultHour
      const defaultmm = defaultminute < 10 ? `0${defaultminute}` : defaultminute
      const defaulthrMin = `${defaulthh}:${defaultmm + 3}`
      hrMin = defaulthrMin
    }
    const { time, days } = this.getUTCTimeDays({
      time: hrMin,
      days: this.state.localDaysNumber == undefined ? '1111111' : this.state.localDaysNumber
    })
    //this block use for period default value send to server
    const defaultperiod = this.state.period == '' ? '0' : this.state.period
    try {
      axios.post(
        `${httpUrl}/data/schedulerule`,
        {
          userId: this.state.userId,
          storeId: this.state.storeId,
          poiId: this.state.poiId,
          endpointId: this.state.endpointId.join(''),
          time: time,
          state: this.state.isIrb === true ? this.state.selectedIRB : this.state.action,
          days: days,
          period: parseInt(defaultperiod),
          repeat: this.state.repeat,
          active: true,
        },
        {
          withCredentials: true,
        },
      ).then(() => {
        this.props.showSnackBar('Rule Create Successfully!!', AddAlertIcon, 'success')
        this.props.onClose(true)
      }).catch((error) => {
        let message
        const { response } = error
        if(response) {
          const { data } = response
          if(data) message = data.message
        }
        if(!message) message = error.message
        console.log('error:', message)
        //console.log('message',message)
        this.props.showSnackBar(message, AddAlertIcon, 'error')
        this.props.onClose(true)
      })
    } catch(e) {
      this.props.showSnackBar('Trouble To Create Rule!!', AddAlertIcon, 'warning')
    }
    
  }
  
  handleCreClose = () => {
    this.props.onClose()
  }
  
  weekButtons = [{
    label: 'Sun', status: 0,
  }, {
    label: 'Mon', status: 0,
  }, {
    label: 'Tue', status: 0,
  }, {
    label: 'Wed', status: 0,
  }, {
    label: 'Thu', status: 0,
  }, {
    label: 'Fri', status: 0,
  }, {
    label: 'Sat', status: 0,
  }]
  
  handleIsirbDropdown = (e) => {
    let selected = e.target.value
    this.setState({ selectedIRB: selected })
  }
  
  render() {
    return (
      <Dialog
        open={true}
        onClose={this.handleCreClose}
        aria-labelledby="form-dialog-title">
        <form onSubmit={this.handleCreateSubmit}>
          <DialogTitle id="form-dialog-title">Create Rule</DialogTitle>
          <DialogContent>
            <TextField
              name="userId"
              onChange={this.handleCreateUserIdChange}
              value={this.state.userId}
              autoFocus
              margin="dense"
              id="userId"
              label="userId"
              type="text"
              variant="standard"
              fullWidth
            />
            <TextField
              name="storeId"
              onChange={this.handleCreateStoreIdChange}
              value={this.state.storeId}
              margin="dense"
              id="storeId"
              label="storeId"
              type="text"
              variant="standard"
              fullWidth
            />
            <TextField
              name="poiId"
              onChange={this.handleCreatePoiIdChange}
              value={this.state.poiId}
              margin="dense"
              id="poiId"
              label="poiId"
              type="text"
              variant="standard"
              fullWidth
            />
            <FormControl fullWidth sx={{ mb: 2 }}>
              <InputLabel>endpointId</InputLabel>
              <Select
                name="endpointId"
                variant="standard"
                onChange={this.handleCreateEndpointIdChange}
                value={this.state.endpointId}
                renderValue={(selected) => (
                  <Box>
                    {selected.map((value) => (
                      <Chip key={value} label={value}/>
                    ))}
                  </Box>
                )}>
                {this.state.apiEndpoinId.map((val, index) => (
                  <MenuItem value={val.endpointId}>{val.name}</MenuItem>
                ))}
                margin="dense"
                id="endpointId"
                label="endpointId"
                fullWidth
              </Select>
            </FormControl>
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              disableEnforceFocus>
              <TimePicker
                label="time"
                name="time"
                value={this.state.time}
                onChange={this.handleCreateTimeChange}
                id="time"
                type="text"
                variant="standard"
                fullWidth
                renderInput={(params) => <TextField {...params} />}
                disableEnforceFocus
              />
            </LocalizationProvider>
            <FormLabel sx={{
              '& > :not(style)': { m: 1, width: '48ch' },
            }}>
              <div className="weekDays">week days</div>
              <TextField
                onChange={this.handleCreateLocalDaysChange}
                value={this.state.localDays}
                variant="outlined"
                InputProps={{
                  endAdornment: <ButtonGroup sx={{ height: 50, marginLeft: -1.5 }}
                                             className="buttonSpace"
                                             edge="start"
                                             variant="contained"
                  >
                    {this.state.weekButtons.map((value, index) => (
                      <Button
                        sx={{
                          backgroundColor: this.state.buttonBackgorunThem, '&:hover': {
                            background: 'rgb(7, 177, 77, 0.42)',
                          },
                        }}
                        style={{ backgroundColor: value.status === 1 ? this.state.buttonColor : null }}
                        onClick={event => this.handleCreateLocalDaysChange(value.label)}>
                        {value.label}
                      </Button>
                    ))}
                  </ButtonGroup>,
                }}>
              </TextField>
            </FormLabel>
            <FormControl className={this.state.classes.FormControl}>
              <TextField
                name="period"
                onChange={this.handleCreatePeriodChange}
                value={this.state.period}
                margin="dense"
                id="period"
                label="period"
                type="text"
                placeholder="Default Value 0"
                variant="standard"
                fullWidth
              />
              <FormLabel>repeat</FormLabel>
              <FormGroup>
                <Grid component="label" container alignItems="center" spacing={1}>
                  <Grid item>No</Grid>
                  <Grid item>
                    <FormControlLabel
                      control={<Switch name="repeat"
                                       defaultChecked
                                       onChange={this.handleCraeteRepeat}
                                       value={this.state.repeat}
                                       margin="dense"
                                       id="repeat"
                                       label="repeat"
                                       fullWidth
                                       color="success"/>}
                    
                    />
                  </Grid>
                  <Grid item>Yes</Grid>
                </Grid>
                <Divider/>
                <FormLabel>action</FormLabel>
                {this.state.isIrb ?
                  <FormControl variant="standard" fullWidth>
                    <InputLabel id="demo-simple-select-label">TRB</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={this.state.selectedIRB}
                      defaultValue
                      label="TRB"
                      onChange={this.handleIsirbDropdown}
                    >
                      {
                        this.state.dropdownOne.map((dOne, dindex) => {
                          return (
                            <MenuItem value={dOne} key={dindex}>{dOne}</MenuItem>
                          )
                        })
                      }
                    </Select>
                  </FormControl> :
                  <Grid component="label" container alignItems="center" spacing={1}>
                    <Grid item>Off</Grid>
                    <Grid item>
                      <FormControlLabel
                        control={<Switch name="action"
                                         defaultChecked
                                         onChange={this.handleCreateAction}
                                         value={this.state.action}
                                         margin="dense"
                                         id="action"
                                         label="action"
                                         fullWidth
                                         color="success"/>
                        }
                      />
                    </Grid>
                    <Grid item>On</Grid>
                  </Grid>
                }
              </FormGroup>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCreClose} color="primary" variant="outlined">
              Cancel
            </Button>
            <Button
              onClick={this.handleCreOk}
              type="submit"
              color="primary"
              variant="outlined"
              disabled={this.state.disabled}>
              OK
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    )
  }
}

export default CreateRuleForm
