import React from "react"
//@mui component
import axios from "axios";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import Button from '@mui/material/Button';
import AddAlertIcon from '@mui/icons-material/AddAlert';

const {REACT_APP_SERVER_URL, REACT_APP_WS_URL} = process.env
const httpUrl = `${REACT_APP_SERVER_URL}`

class DeleteRuleFrom extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            classes: props.classes,
            tabData: [],
            history: props.history,
            deviceId: "",
            delOpen: props.delOpen,
            modOpen: false,
            tc: false,
            poiId: props.rowDtails.poiId,
            userId: props.rowDtails.userId,
            storeId: props.rowDtails.storeId,
            endpointId: props.rowDtails.endpointId,
            ruleId: props.rowDtails.ruleId,
            time: new Date(),
            days: "",
            period: "",
            localDays: [],
            buttonColor: 'rgb(' + (255) + ',' + (0) + ',' + (0) + ')',
            tce: false,
            weekButtons: this.weekButtons
        };

    }

    handleDelOk = () => {
        this.deleteApiCall(this.state.userId, this.state.storeId, this.state.poiId, this.state.endpointId, this.state.ruleId)
    }

    handleDelClose = () => {
        this.props.onClose()
    }

    async deleteApiCall(userId, storeId, poiId, endpointId, ruleId) {
        try {
            await axios.delete(`${httpUrl}/data/schedulerule/${endpointId}/${ruleId}`,
                {
                    withCredentials: true,
                },).then((res) => {
                this.props.showSnackBar("Rule Delete Successfully!!", AddAlertIcon, "success")
                this.props.onClose(true)
            }).catch((error)=>{
                let message
                const { response } = error
                if (response) {
                    const { data } = response
                    if (data) message = data.message
                }
                if(!message) message = error.message
                console.log('error:', message)
                //console.log('message',message)
                this.props.showSnackBar(message, AddAlertIcon, "error")
                this.props.onClose(true)
            })
        } catch (e) {
            console.log('error message',e)
            this.props.showSnackBar("Trouble To Rule Delete!!", AddAlertIcon, "error")
            this.props.onClose(true)
        }
    }

    render() {
        return (
            <Dialog
                open={this.state.delOpen}
                onClose={this.handleDelClose}
                aria-labelledby='alert-dialog-title'
                aria-describedby='alert-dialog-description'>
                <DialogTitle id='alert-dialog-title'>{'Rule Device Removal'}</DialogTitle>
                <DialogContent>
                    <DialogContentText id='alert-dialog-description'>
                        Are you sure to delete the Rule Device ?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleDelClose} color='primary'>
                        Cancel
                    </Button>
                    <Button onClick={this.handleDelOk} color='primary'>
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}

export default DeleteRuleFrom
