import React from 'react'
import DialogTitle from '@mui/material/DialogTitle'
import {
  Box,
  Chip,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@mui/material'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import axios from 'axios'
import _ from 'lodash'
import AddAlertIcon from '@mui/icons-material/AddAlert'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { TimePicker } from '@mui/x-date-pickers/TimePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import ButtonGroup from '@mui/material/ButtonGroup'
import Switch from '@mui/material/Switch'
import Grid from '@mui/material/Grid'

const { REACT_APP_SERVER_URL } = process.env
const httpUrl = `${REACT_APP_SERVER_URL}`

//let isIrb = true
class CreateEventRuleForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      createEventOpen: props.createEventOpen,
      userId: '',
      storeId: '',
      poiId: '',
      srcEndpointId: '',
      destEndpointId: '',
      threshold: '',
      srcEndpoinIds: [],
      destEndpoinIds: [],
      deviceData: [],
      dropdownOne: [],
      dropdownConditions: [],
      isIrb: false,
      selectedIRB: '',
      time: new Date(),
      localDays: [],
      weekButtons: this.weekButtons,
      buttonBackgorunThem: '#616161',
      buttonColor: 'rgb(' + (76) + ',' + (175) + ',' + (80) + ')',
      duration: '',
      selectedCondition: '',
      nonIrbSwitch: '1',
    }
  }
  
  deviceDataType(userId) {
    axios.post(`${httpUrl}/data/getDataSnapshot`, {
        userId,
        allControls: true,
      },
      {
        withCredentials: true,
      })
      .then(res => {
        this.setState({ deviceData: res.data })
        this.checkIRB()
      }).catch((error) => {
      let message
      const { response } = error
      if(response) {
        const { data } = response
        if(data) message = data.message
      }
      if(!message) message = error.message
      this.props.showSnackBar(message, AddAlertIcon, 'error')
      this.props.onClose(true)
    })
  }
  
  componentDidMount() {
    const dropdownData = ['PWR_ON', 'PWR_OFF', 'TEMP_18', 'TEMP_19', 'TEMP_20', 'TEMP_21', 'TEMP_22', 'TEMP_23',
      'TEMP_24', 'TEMP_25', 'TEMP_26', 'TEMP_27', 'TEMP_28', 'FAN_1', 'FAN_2', 'FAN_3', 'FAN_4', 'FAN_5']
    const dropdownConditions = ['>=','<=','==']
    this.setState({ dropdownOne: dropdownData,dropdownConditions: dropdownConditions })
  }
  
  checkIRB = () => {
    this.state.destEndpoinIds.map((destEndpoint) => {
      const endId = _.first(_.first(this.state.destEndpointId).split('.'))
      let deviceItem = _.find(this.state.deviceData, { deviceType: 'IRB', deviceId: endId }) //find IRB then match deviceType or endpointId
      if(!_.isEmpty(deviceItem)) {
        this.setState({ selectedIRB: destEndpoint.state })
      }
      _.isEmpty(deviceItem) ? this.setState({ isIrb: false }) : this.setState({ isIrb: true })
    })
  }
  
  handleCreateUserIdChange = (uId) => {
    this.setState({ userId: uId.target.value })
    setTimeout(() => {
      this.endpointApiCall()
    }, 1000)
  }
  
  handleCreateStoreIdChange = (sId) => {
    this.setState({ storeId: sId.target.value })
    setTimeout(() => {
      this.endpointApiCall()
    }, 1000)
  }
  
  handleCreatePoiIdChange = (pId) => {
    this.setState({ poiId: pId.target.value })
    setTimeout(() => {
      this.endpointApiCall()
    }, 1000)
  }
  
  endpointApiCall(deviceType) {
    axios.post(`${httpUrl}/data/getEndpointsSnapshot`,
      {
        userId: this.state.userId,
        storeId: this.state.storeId,
        poiId: this.state.poiId,
        deviceType: 'AQI_SENSOR',
      },
      {
        withCredentials: true,
      })
      .then((res) => {
        console.log('src Data', res.data)
        this.setState({ srcEndpoinIds: res.data })
      })
      .catch((error) => {
        let message
        const { response } = error
        if(response) {
          const { data } = response
          if(data) message = data.message
        }
        if(!message) message = error.message
        this.props.showSnackBar(message, AddAlertIcon, 'error')
        this.props.onClose(true)
      })
    axios.post(`${httpUrl}/data/getEndpointsSnapshot`,
      {
        userId: this.state.userId,
        allControls: true,
      },
      {
        withCredentials: true,
      })
      .then((res) => {
        //this.props.showSnackBar('Event Rule Create Successfully!!', AddAlertIcon, 'success')
        this.setState({ destEndpoinIds: res.data })
      })
      .catch((error) => {
        let message
        const { response } = error
        if(response) {
          const { data } = response
          if(data) message = data.message
        }
        if(!message) message = error.message
        this.props.showSnackBar(message, AddAlertIcon, 'error')
        this.props.onClose(true)
      })
  }
  
  handleCreateSourceendpointIdChange = (sEId) => {
    const {
      target: { value },
    } = sEId
    
    this.setState({ srcEndpointId: typeof value === 'string' ? value.split(',') : value })
  }
  
  handleCreateDestinationendpointIdChange = (dEId) => {
    const {
      target: { value },
    } = dEId
    
    this.setState({ destEndpointId: typeof value === 'string' ? value.split(',') : value })
    this.deviceDataType(this.state.userId)
  }
  
  handleSelectConditionChange = (selectedValue) => {
    //console.log('handleSelectConditionChange', selectedValue)
    this.setState({selectedCondition: selectedValue.target.value})
  }
  
  handleCreateEeventClose = () => {
    this.props.onClose()
  }
  
  handleThreshold = (e) => {
    const {
      target: { value },
    } = e
    this.setState({ threshold: value })
  }
  
  //this function handleCreateDays use for based on time days conversion
  
  leftShift(str, n) {
    return str.substring(n) + str.substring(0, n)
  }
  
  rightShift(str, n) {
    let l = str.length - n
    return this.leftShift(str, l)
  }
  
  getUTCTimeDays = ({ time, days }) => {
    let utcDays = days
    const hour = Number(_.chain(time).split(':').head().value())
    const min = Number(_.chain(time).split(':').last().value())
    const date = new Date()
    const tzOffset = date.getTimezoneOffset()
    const localDay = date.getDay()
    date.setHours(hour)
    date.setMinutes(min)
    const utcDay = date.getUTCDay()
    const utcHour = date.getUTCHours()
    const utcMinutes = date.getUTCMinutes()
    let utcTime = _.padStart(`${utcHour}`, 2, '0') + ':' + _.padStart(`${utcMinutes}`, 2, '0')
    if((localDay !== utcDay) && (tzOffset > 0)) utcDays = this.rightShift(days, 1)
    if((localDay !== utcDay) && (tzOffset < 0)) utcDays = this.leftShift(days, 1)
    return { time: utcTime, days: utcDays }
  }
  
  handleCreateSubmit = async (e) => {
    e.preventDefault()
    const { userId, storeId, poiId, srcEndpointId, threshold, destEndpointId, localDays} = this.state
  
    const LocalTime = this.state.time
    const hour = LocalTime.$H
    const minute = LocalTime.$m
    const hh = hour < 10 ? `0${hour}` : hour
    const mm = minute < 10 ? `0${minute}` : minute
    let hrMin = `${hh}:${mm}`
    //default time add send to server
    if(hrMin == 'undefined:undefined') {
      const defaultHour = new Date().getHours()
      const defaultminute = new Date().getMinutes()
      const defaulthh = defaultHour < 10 ? `0${defaultHour}` : defaultHour
      const defaultmm = defaultminute < 10 ? `0${defaultminute}` : defaultminute
      const defaulthrMin = `${defaulthh}:${defaultmm + 3}`
      hrMin = defaulthrMin
    }
    const { time, days } = this.getUTCTimeDays({
      time: hrMin,
      days: this.state.localDaysNumber == undefined ? '1111111' : this.state.localDaysNumber
    })
    //this block use for period default value send to server
    const defaultDuration = this.state.duration == '' ? 0 : parseInt(this.state.duration)
    /*console.log(' time',  time)
    console.log('days', days )
    console.log('defaultDuration', defaultDuration )*/
    
    if(_.isEmpty(userId)) {
      return alert('Please input userId')
    }
    if(_.isEmpty(storeId)) {
      return alert('Please input storeId')
    }
    if(_.isEmpty(poiId)) {
      return alert('Please input poiId')
    }
    if(_.isEmpty(srcEndpointId)) {
      return alert('Please select srcEndpointId')
    }
    if(_.isEmpty(threshold)) {
      return alert('Please input threshold')
    }
    if(_.isEmpty(destEndpointId)) {
      return alert('Please input destEndpointId')
    }
    console.log('this.state.selectedIRB!!!!', this.state.selectedIRB)
    const rule1Data = {
      userId: userId,
      storeId: storeId,
      poiId: poiId,
      endpointId: `${srcEndpointId}`,
      trigger: {
        endpointId: `${srcEndpointId}`,
        relOp: this.state.selectedCondition, //pivk up from select box valkue
        value: `${threshold}`
      },
      actions: [{
        endpointId: `${destEndpointId}`,
        state: this.state.isIrb === true ? this.state.selectedIRB : this.state.nonIrbSwitch
      }],
      allowedTimePeriod: {
        days: days,
        startTime: time,
        period: defaultDuration
      },
      active: true
    }
    /*const rule2Data = {
      userId: userId,
      storeId: storeId,
      poiId: poiId,
      endpointId: `${srcEndpointId}`,
      trigger: {
        endpointId: `${srcEndpointId}`,
        relOp: '<',
        value: `${threshold}`
      },
      actions: [{
        endpointId: `${destEndpointId}`,
        state: this.state.isIrb === true ? this.state.selectedIRB : '0'
      }],
      allowedTimePeriod: {
        days: days,
        startTime: time,
        period: defaultDuration
      },
      active: true
    }*/
    try {
      const promises = []
      const promiseRule1Create = new Promise((ok, fail) => {
        axios.post(`${httpUrl}/data/eventrule`,
          rule1Data,
          { withCredentials: true, }
        )
          .then((res) => {
            ok()
            this.props.showSnackBar('Event Rule Create Successfully!!', AddAlertIcon, 'success')
          })
          .catch((err) => {
            fail(err)
          })
      })
      promises.push(promiseRule1Create)
      /*const promiseRule2Create = new Promise((ok, fail) => {
        axios.post(`${httpUrl}/data/eventrule`,
          rule2Data,
          { withCredentials: true, }
        )
          .then((res) => {
            ok()
            this.props.showSnackBar('Event Rule Create Successfully!!', AddAlertIcon, 'success')
          })
          .catch((err) => {
            fail(err)
          })
      })
      promises.push(promiseRule2Create)*/
      await Promise.all(promises)
    } catch(e) {
      const message = e.response.data.message || e.response.statusText
      return (
        this.props.showSnackBar({ message }, AddAlertIcon, 'success')
      )
    }
    this.props.onClose(true)
  }
  
  handleCreateEventClose = (e) => {
    console.log('close')
    this.props.onClose()
  }
  
  handleIrbDropdownChange = (e) => {
    console.log('handleIrbDropdownChange', e.target.value)
    let selected = e.target.value
    this.setState({ selectedIRB: selected })
  }
  
  nonIrbSwitchAction = (e) => {
    this.setState({nonIrbSwitch: '0'})
  }
  
  handleCreateTimeChange = (time) => {
    console.log('time', time)
    this.setState({ time: time })
  }
  
  handleCreateLocalDaysChange = (lDays) => {
    //this.setState({localDays: '1111111'})
    console.log('lDays', lDays)
    const index = this.state.weekButtons.findIndex(item => lDays === item.label)
    this.state.weekButtons[index].status = 1
    const localDays = this.state.weekButtons
      .reduce((newArray, item) => (item.status ? [...newArray, item.label] : newArray), [])
    this.setState({ localDays: localDays, weekButtons: this.state.weekButtons })
    /*DAYS CONVERSION 1010011 START*/
    console.log('localDays', localDays)
    const targetValue = localDays
    const targetDays = [{
      DaysName: 'Sun',
      status: 0,
    }, {
      DaysName: 'Mon',
      status: 0,
    }, {
      DaysName: 'Tue',
      status: 0,
    }, {
      DaysName: 'Wed',
      status: 0,
    }, {
      DaysName: 'Thu',
      status: 0,
    }, {
      DaysName: 'Fri',
      status: 0,
    }, {
      DaysName: 'Sat',
      status: 0,
    }]
    const weeksDaysNumber = []
    targetValue.forEach((selectedWeek) => {
      targetDays.forEach((value, index) => {
        if(value.DaysName === selectedWeek) {
          value.status = 1
        }
      })
    })
    targetDays.forEach((statusValue) => {
      weeksDaysNumber.push(statusValue.status)
    })
    const localDaysNumber = weeksDaysNumber.join('')
    this.setState({ localDaysNumber: weeksDaysNumber.join('') })
    /*DAYS CONVERSION 1010011 END*/
  }
  
  weekButtons = [{
    label: 'Sun', status: 0,
  }, {
    label: 'Mon', status: 0,
  }, {
    label: 'Tue', status: 0,
  }, {
    label: 'Wed', status: 0,
  }, {
    label: 'Thu', status: 0,
  }, {
    label: 'Fri', status: 0,
  }, {
    label: 'Sat', status: 0,
  }]
  
  handleCreateDurationChange = (peId) => {
    this.setState({ duration: peId.target.value })
  }
  
  render() {
    return (
      <Dialog
        open={true}
        onClose={this.handleCreateEeventClose}
        aria-labelledby="form-dialog-title">
        <form onSubmit={this.handleCreateSubmit}>
          <DialogTitle id="form-dialog-title">Create Rule</DialogTitle>
          <DialogContent>
            <TextField
              name="userId"
              onChange={this.handleCreateUserIdChange}
              value={this.state.userId}
              autoFocus
              margin="dense"
              id="userId"
              label="User Id"
              type="text"
              variant="standard"
              fullWidth
            />
            <TextField
              name="storeId"
              onChange={this.handleCreateStoreIdChange}
              value={this.state.storeId}
              margin="dense"
              id="storeId"
              label="Store Id"
              type="text"
              variant="standard"
              fullWidth
            />
            <TextField
              name="poiId"
              onChange={this.handleCreatePoiIdChange}
              value={this.state.poiId}
              margin="dense"
              id="poiId"
              label="PoI Id"
              type="text"
              variant="standard"
              fullWidth
            />
            <FormControl fullWidth sx={{ mb: 2 }}>
              <InputLabel>Source Endpoint Id</InputLabel>
              <Select
                name="source endpoint Id"
                variant="standard"
                onChange={this.handleCreateSourceendpointIdChange}
                value={this.state.srcEndpointId}
                renderValue={(selected) => (
                  <Box>
                    {selected.map((value) => (
                      <Chip key={value} label={value}/>
                    ))}
                  </Box>
                )}>
                {this.state.srcEndpoinIds.map((val, index) => (
                  <MenuItem key={index} value={val.endpointId}>{val.name}</MenuItem>
                ))}
                margin="dense"
                id="endpointId"
                label="sourceendpointId"
                fullWidth
              </Select>
            </FormControl>
            <TextField
              name="threshold"
              onChange={this.handleThreshold}
              value={this.state.threshold}
              margin="dense"
              id="threshold"
              label="Threshold"
              type="text"
              variant="standard"
              fullWidth
            />
            <FormControl fullWidth sx={{ mb: 2 }}>
              <InputLabel>Select Condition </InputLabel>
              <Select
                name="destinationendpointId"
                variant="standard"
                onChange={this.handleSelectConditionChange}
                value={this.state.selectedCondition}>
                {
                  this.state.dropdownConditions.map((val, index) => (
                    <MenuItem value={val} key={index}>{val}</MenuItem>
                  ))}
                margin="dense"
                id="selectConditionId"
                label="SelectCondition"
                fullWidth
              </Select>
            </FormControl>
            <FormControl fullWidth sx={{ mb: 2 }}>
              <InputLabel>Destination Endpoint Id</InputLabel>
              <Select
                name="destinationendpointId"
                variant="standard"
                onChange={this.handleCreateDestinationendpointIdChange}
                value={this.state.destEndpointId}
                renderValue={(selected) => (
                  <Box>
                    {selected.map((value) => (
                      <Chip key={value} label={value}/>
                    ))}
                  </Box>
                )}>
                {
                  this.state.destEndpoinIds.map((val, index) => (
                    <MenuItem value={val.endpointId}>{val.name}</MenuItem>
                  ))}
                margin="dense"
                id="destinationendpointId"
                label="destinationendpointId"
                fullWidth
              </Select>
            </FormControl>
            {this.state.isIrb ?
              <FormControl variant="standard" fullWidth>
                <InputLabel id="demo-simple-select-label">TRB</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={this.state.selectedIRB}
                  label="TRB"
                  onChange={this.handleIrbDropdownChange}
                >
                  {
                    this.state.dropdownOne.map((dOne, dindex) => {
                      return (
                        <MenuItem value={dOne} key={dindex}>{dOne}</MenuItem>
                      )
                    })
                  }
                </Select>
              </FormControl>
              : <Grid component="label" container alignItems="center" spacing={1}>
                <Grid item>Off</Grid>
                <Grid item>
                  <FormControlLabel
                    control={<Switch name="action"
                                     defaultChecked
                                     onChange={this.nonIrbSwitchAction}
                                     value={this.state.nonIrbSwitch}
                                     margin="dense"
                                     id="action"
                                     label="action"
                                     fullWidth
                                     color="success"/>
                    }
                  />
                </Grid>
                <Grid item>On</Grid>
              </Grid>}
            <FormLabel sx={{
              '& > :not(style)': { m: 0, width: '40ch' },
            }}>
              <div>Allowed Time Period</div>
            </FormLabel>
            <FormLabel sx={{
              '& > :not(style)': { mb: 1, width: '48ch' },
            }}>
              <div className="weekDays">week days</div>
              <TextField
                onChange={this.handleCreateLocalDaysChange}
                value={this.state.localDays}
                variant="outlined"
                InputProps={{
                  endAdornment: <ButtonGroup sx={{ height: 50, marginLeft: -1.5 }}
                                             className="buttonSpace"
                                             edge="start"
                                             variant="contained"
                  >
                    {this.state.weekButtons.map((value, index) => (
                      <Button
                        sx={{
                          backgroundColor: this.state.buttonBackgorunThem, '&:hover': {
                            background: 'rgb(7, 177, 77, 0.42)',
                          },
                        }}
                        style={{ backgroundColor: value.status === 1 ? this.state.buttonColor : null }}
                        onClick={event => this.handleCreateLocalDaysChange(value.label)}
                        key={index}>
                        {value.label}
                      </Button>
                    ))}
                  </ButtonGroup>,
                }}>
              </TextField>
            </FormLabel>
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              disableEnforceFocus>
              <TimePicker
                label="Start Time"
                name="time"
                value={this.state.time}
                onChange={this.handleCreateTimeChange}
                id="time"
                type="text"
                variant="standard"
                fullWidth
                renderInput={(params) => <TextField {...params} />}
                disableEnforceFocus
              />
            </LocalizationProvider>
            <TextField
              name="duration"
              onChange={this.handleCreateDurationChange}
              value={this.state.duration}
              margin="dense"
              id="duration"
              label="duration"
              type="text"
              placeholder="Default Value 0"
              variant="standard"
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCreateEeventClose} color="primary">
              Cancel
            </Button>
            <Button
              type="submit"
              color="primary">
              OK
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    )
  }
}

export default CreateEventRuleForm
