import React from 'react'
import axios from 'axios'
//@mui/material/component
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import withStyles from '@mui/styles/withStyles';
// core components
import GridItem from 'components/Grid/GridItem.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'
import Card from 'components/Card/Card.jsx'
import CardHeader from 'components/Card/CardHeader.jsx'
import CardBody from 'components/Card/CardBody.jsx'
import {w3cwebsocket as W3CWebSocket} from 'websocket'
import {DataGrid} from '@mui/x-data-grid'
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";

const {REACT_APP_SERVER_URL, REACT_APP_WS_URL} = process.env
const httpUrl = `${REACT_APP_SERVER_URL}`
const wsUrl = `${REACT_APP_WS_URL}`
const styles = {
    cardCategoryWhite: {
        '&,& a,& a:hover,& a:focus': {
            color: 'rgba(255,255,255,.62)',
            margin: '0',
            fontSize: '14px',
            marginTop: '0',
            marginBottom: '0',
        },
        '& a,& a:hover,& a:focus': {
            color: '#FFFFFF',
        },
    },
    cardTitleWhite: {
        color: '#FFFFFF',
        marginTop: '0px',
        minHeight: 'auto',
        fontWeight: '300',
        fontFamily: '\'Roboto\', \'Helvetica\', \'Arial\', sans-serif',
        marginBottom: '3px',
        textDecoration: 'none',
        '& small': {
            color: '#777',
            fontSize: '65%',
            fontWeight: '400',
            lineHeight: '1',
        },
    },
}

class EndpointsList extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            classes: props.classes,
            tabData: [],
            history: props.history,
            deviceId: '',
            userId: '00030001',
            delOpen: false,
            tc: false,
            tce: false,
            modOpen: false,
            modifiedName: '',
            modRowData: [],
        }
    }

    seriesMap = {}
    labelMap = {}
    ws = {}
    tmoutfn = {}

    componentWillUnmount() {
        clearInterval(this.tmoutfn)
        this.ws.close()
    }

    refreshData(userId) {
        axios.post(`${httpUrl}/data/getEndpointsSnapshot`, {
                userId,
                deviceType: 'AQI_SENSOR'
            },
            {
                withCredentials: true,
            })
            .then(res => {
                this.setState({tabData: res.data})
            })
    };

    connectStream() {
        this.ws = new W3CWebSocket(`${wsUrl}/livestream`)
        var self = this
        this.ws.onopen = () => {
            // on connecting, send userid data interested in
            console.log('connected')
            //This uid not used... for sample only.. quarkifi access uid only used
            self.ws.send(JSON.stringify({'userId': 'piNtWTzdwKU1SNZGBHCBbzy3tc42'}))
            this.tmoutfn = setInterval(function () {
                self.ws.send(JSON.stringify({'keepAlive': true}))
            }, 10000)

        }

        this.ws.onmessage = evt => {
            // listen to data sent from the websocket server
            const devData = JSON.parse(evt.data)
            const payload = devData.eventData
            if (devData.eventType === 'ENDPOINT_UPDATE' || devData.eventType === 'ENDPOINT_CONFIG_UPDATE') {
                console.log('event received')
                var curData = self.state.tabData
                for (let i = 0; i < curData.length; i++) {
                    if (curData[i].endpointId === payload.endpointId) {
                        curData[i].name = payload.name
                        curData[i].state = payload.state
                        curData[i].timestamp = payload.timestamp
                        self.setState({tabData: curData})
                    }
                }
            } else if (devData.eventType === 'DEVICE_DELETE') {
                console.log('event received')
                const curData = self.state.tabData
                for (let i = 0; i < curData.length; i++) {
                    if (curData[i].endpointId.contains(payload.deviceId)) {
                        curData.splice(i, 1)
                        self.setState({tabData: curData})
                    }
                }
            }
            console.log(devData)
        }

        this.ws.onclose = () => {
            clearInterval(this.tmoutfn)
            console.log('disconnected')
            // automatically try to reconnect on connection loss
        }
    }

    async componentDidMount() {
        this.refreshData(this.state.userId)
        this.connectStream()
    };

    handleOpen = () => {
        this.setState({delOpen: true})
    }


    handleClose = () => {
        this.setState({delOpen: false})
    }

    async componentDidMount() {

        this.refreshData(this.state.userId)

        this.connectStream()

    };

    handleOpen = () => {
        this.setState({delOpen: true})
    }

    handleClose = () => {
        this.setState({delOpen: false})
    }

    handleUserIdChange = (e) => {
        this.setState({userId: e.target.value})
    }

    handleNameChange = (e) => {
        this.setState({modifiedName: e.target.value})
    }

    handleModClose = () => {
        this.setState({modOpen: false})
    }

    handleModOk = () => {
        const {col2, userId} = this.state.modRowData
        axios.post(`${httpUrl}/data/modEndpoints`,
            {
                userId: userId,
                deviceId: col2.split('.')[0],
                endpoints: [
                    {
                        endpointId: col2,
                        name: this.state.modifiedName,
                    }
                ]
            }
        ).then((res) => {
            console.log('res api', res)
            this.refreshData(userId)
            this.setState({modOpen: false})
        }).catch((err) => {
            console.log('err', err)
        })
    }

    render() {
        const columns = [
            {field: 'col1', headerName: 'Name', width: 150},
            {field: 'col2', headerName: 'Id', width: 150},
            {field: 'col3', headerName: 'Value', width: 150},
            {
                field: 'col4',
                headerName: 'Last Update At',
                width: 450,
                renderCell: params => <span>{new Date(params.row.col4 * 1000).toString()}</span>
            },
            {
                field: 'col5', headerName: 'Action', width: 150,
                renderCell: params =>
                    <IconButton size="large">
                        <EditIcon
                            onClick={(event) => this.setState({
                                modRowData: params.row,
                                modOpen: true,
                            })
                            }/>
                    </IconButton>
            }
        ]
        const sensorEndpoints = this.state.tabData.map((row, index) => {
            let row_ = {
                id: index,
                col1: row.name,
                col2: row.endpointId,
                col3: row.state,
                col4: row.timestamp,
                userId: row.userId
            }
            return row_
        })

        return (
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <Dialog
                        open={this.state.modOpen}
                        onClose={this.handleModClose}
                        aria-labelledby='alert-dialog-title'
                        aria-describedby='alert-dialog-description'>
                        <DialogTitle id='alert-dialog-title'>{'Sensor Config Edit'}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id='alert-dialog-description'>
                                Update Following fields
                            </DialogContentText>
                            <TextField value={this.state.modifiedName} onChange={this.handleNameChange}
                                       label='Endpoint Name' variant='outlined'/>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.handleModClose} color='primary'>
                                Cancel
                            </Button>
                            <Button onClick={this.handleModOk} color='primary'>
                                OK
                            </Button>

                        </DialogActions>
                    </Dialog>
                    <Card>
                        <CardHeader color='success'>
                            <h4 className={this.state.classes.cardTitleWhite}>Sensor Endpoints</h4>
                            <p className={this.state.classes.cardCategoryWhite}>
                                View IAQ Sensors
                            </p>
                        </CardHeader>
                        <CardBody>
                            <TextField style={{padding: '10px'}} value={this.state.userId}
                                       onChange={this.handleUserIdChange}
                                       label='UserId' variant='outlined'/>
                            <Button onClick={() => this.refreshData(this.state.userId)} color='primary'
                                    variant='outlined'>
                                Refresh
                            </Button>
                            <DataGrid
                                sx={{height: 500, width: '100%'}}
                                columns={columns}
                                rows={sensorEndpoints}
                                resize={this.createRow}
                            />
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
        )
    }
}

export default withStyles(styles)(EndpointsList)
