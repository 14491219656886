import React from "react";
import classNames from "classnames";
import axios from "axios";
import withStyles from '@mui/styles/withStyles';
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Hidden from "@mui/material/Hidden";
import Poppers from "@mui/material/Popper";
// @mui/icons-material
import Person from "@mui/icons-material/Person";
import Notifications from "@mui/icons-material/Notifications";
import Dashboard from "@mui/icons-material/Dashboard";
import Search from "@mui/icons-material/Search";
// core components
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";

import headerLinksStyle from "assets/jss/material-dashboard-react/components/headerLinksStyle.jsx";

const { REACT_APP_SERVER_URL } = process.env;

class HeaderLinks extends React.Component {
  state = {
    open: false,
    profilePopupOpen: false,
  };

  handleToggle = () => {
    this.setState(state => ({ open: !state.open, profilePopupOpen: false }));
  };

  handleToggleProfile = () => {
    this.setState(state => ({ profilePopupOpen: !state.profilePopupOpen, open: false }));
  };

  handleClose = event => {
    if (this.anchorEl.contains(event.target)) {
      return;
    }

    this.setState({ open: false, profilePopupOpen: false });
  };

  logout = async () => {
    const { history } = this.props;
    let logoutRequest;
    try {
      logoutRequest = await axios.post(
        `https://${REACT_APP_SERVER_URL}/logout`, {}, {
          withCredentials: true
        }
      );
    } catch ({ request }) {
      
      logoutRequest = request;
    }
    if (logoutRequest.status === 301) {
      history.push('/auth/login-page');
    }
  }

  render() {
    const { classes } = this.props;
    const { open, profilePopupOpen } = this.state;
    return (
      <div>
        <div className={classes.manager}>
          <Poppers
            open={profilePopupOpen}
            anchorEl={this.anchorEl}
            transition
            disablePortal
            className={
              classNames({ [classes.popperClose]: !profilePopupOpen }) +
              " " +
              classes.pooperNav
            }
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                id="menu-list-grow"
                style={{
                  transformOrigin:
                    placement === "bottom" ? "center top" : "center bottom"
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={this.handleClose}>
                    <MenuList role="menu">
                      
                      <MenuItem
                        onClick={this.logout}
                        className={classes.dropdownItem}
                      >
                        Logout
                      </MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Poppers>
        </div>
      </div>
    );
  }
}

export default withStyles(headerLinksStyle)(HeaderLinks);
