import React from 'react'
import GridItem from 'components/Grid/GridItem.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'
import Card from 'components/Card/Card.jsx'

import CardBody from 'components/Card/CardBody.jsx'
import '../../style/DeviceStyle/RuleList.jsx.css'
import { DataGrid } from '@mui/x-data-grid'
import IconButton from '@mui/material/IconButton'
import DeleteIcon from '@mui/icons-material/Delete'
import CardHeader from '../../components/Card/CardHeader'
import Button from '@mui/material/Button'
import withStyles from '@mui/styles/withStyles'
import DeleteEventRuleForm from './DeleteEventRuleForm'
import CreateEventRuleForm from './CreateEventRuleForm'
import axios from 'axios'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'
import { green, red } from '@mui/material/colors'
import Snackbar from '../../components/Snackbar/Snackbar'
import TextField from '@mui/material/TextField'
import Box from '@mui/material/Box'
import { Chip } from '@mui/material'
import { getDisplayDays, getLocalTimeDays } from './utils'
import EditIcon from '@mui/icons-material/Edit'
import ModificationRule from './ModificationRule'

const styles = {
  FormControl: {
    width: 400
  },
  cardCategoryWhite: {
    '&,& a,& a:hover,& a:focus': {
      color: 'rgba(255,255,255,.62)', margin: '0', fontSize: '14px', marginTop: '0', marginBottom: '0',
    }, '& a,& a:hover,& a:focus': {
      color: '#FFFFFF',
    },
  }, cardTitleWhite: {
    color: '#FFFFFF',
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: '\'Roboto\', \'Helvetica\', \'Arial\', sans-serif',
    marginBottom: '3px',
    textDecoration: 'none',
    '& small': {
      color: '#777', fontSize: '65%', fontWeight: '400', lineHeight: '1',
    },
  }, buttonBgColor: {
    color: '#cccccc'
  },
}

const { REACT_APP_SERVER_URL } = process.env
const httpUrl = `${REACT_APP_SERVER_URL}`

class EventRuleList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      classes: props.classes,
      delOpen: false,
      modOpen: false,
      rowDtails: [],
      createEventOpen: false,
      userId: 'boschuser',
      tabData: [],
      days: '',
      snackBar: {
        message: null,
        status: false,
        icon: null,
        color: null
      },
      reRender: false,
    }
  }
  
  showSnackBar = (message, icon, color) => {
    console.log('color', color)
    this.setState({
      ...this.state, snackBar: {
        message: message,
        status: true,
        icon: icon,
        color: color
      }
    })
    setTimeout(() => {
      this.setState({
        snackBar: {
          status: false
        }
      })
    }, 6000)
  }
  
  async componentDidMount() {
    this.refreshData(this.state.userId)
  };
  
  refreshData(userId) {
    axios.get(`${httpUrl}/data/eventrule/${userId}`)
      .then(res => {
        const eventRules = res.data
        const updatedRules = eventRules.map((rule) => {
          const {allowedTimePeriod} = rule
          const { time: localTime, days: localDays } = getLocalTimeDays({ time: allowedTimePeriod.startTime, days: allowedTimePeriod.days })
          const updatedRule = {
            ...rule, time: localTime, days: localDays
          }
         
          updatedRule.days = getDisplayDays(updatedRule.days)
          return updatedRule
          
        })
       
        this.setState({ tabData: updatedRules })
      })
    
  }
  
  reRender() {
    console.log('rerender')
    this.setState({ reRender: true })
  }
  
  editRuleIconClick(e, rowData) {
    console.log('rowData', rowData)
    const fullRowData = {
      active: rowData.active,
      days: rowData.days,
      endpointId: rowData.endpointId,
      period: rowData.duration,
      poiId: rowData.poiId,
      repeat: rowData.repeat,
      ruleId: rowData.ruleId,
      state: rowData.state,
      storeId: rowData.storeId,
      time: rowData.startTime,
      userId: rowData.userId,
    }
    this.setState({ rowDtails: fullRowData, modOpen: true })
  }
  
  deleteEventRuleIconClick(e, rowData) {
    const fullRowData = {
      id: rowData.id,
      userId: rowData.userId,
      storeId: rowData.storeId,
      poiId: rowData.poiId,
      endpointId: rowData.endpointId,
      ruleId: rowData.ruleId,
      sourceendpointId: rowData.sourceendpointId,
      destinationendpointId: rowData.destinationendpointId,
    }
    console.log('delete fullRowData', fullRowData)
    this.setState({ rowDtails: fullRowData, delOpen: true })
  }
  
  
  handleModClose = (done) => {
    this.setState({ modOpen: false })
    if(done) this.refreshData(this.state.userId)
  }
  
  handleDelClose = (done) => {
    this.setState({ delOpen: false })
    if(done) this.refreshData(this.state.userId)
  }
  
  handleCreateEventClose = (done) => {
    this.setState({ createEventOpen: false })
    if(done) this.refreshData(this.state.userId)
  }
  
  handleUserIdChange = (e) => {
    this.setState({ userId: e.target.value })
  }
  
  handleCreateEventOpen = () => {
    this.setState({ createEventOpen: true })
  }
  
  render() {
    let columns = [
      { headerName: 'User ID', field: 'userId', width: 100 },
      { headerName: 'Store Id', field: 'storeId', width: 100 },
      { headerName: 'Poi Id', field: 'poiId', width: 100 },
      { headerName: 'EndpointId', field: 'endpointId', width: 150 },
      /*{headerName: 'Endpoint Name', field: 'endpointName', width: 150},*/
      { headerName: 'Trigger', field: 'trigger', width: 200 },
      { headerName: 'Action', field: 'action', width: 250 },
      {
        headerName: 'Days', field: 'days', width: 350,
        renderCell: (cellRow) => {
          return (<div className="days">
            {cellRow.row.days.map((day) => (<Chip label={day} color="primary" size="medium"
                                                  variant="outlined"/>))}
          </div>)
        }
      },
      { headerName: 'Start Time', field: 'startTime', width: 80 },
      { headerName: 'Duration', field: 'duration', width: 80 },
      {
        headerName: 'Active', field: 'active', width: 60,
        renderCell: rowData => (rowData.row.active === false || !rowData.row.active) ?
          <FiberManualRecordIcon style={{ color: red[500] }}/> : <FiberManualRecordIcon
            style={{ color: green[500] }}/>
      },
      {
        headerName: 'Actions', field: 'Action', width: 120,
        renderCell: (params) => {
          return <>
            <IconButton
              onClick={(event) => this.editRuleIconClick(event, params.row)}
              size="large">
              <EditIcon/>
            </IconButton>
            <IconButton
              onClick={(event) => {
                this.deleteEventRuleIconClick(event, params.row)
              }}
              size="large">
              <DeleteIcon/>
            </IconButton>
          </>
        }
      },
    ]
    let rows =
      this.state.tabData.map((val, index) => {
        return {
          id: index,
          userId: val.userId,
          storeId: val.storeId,
          poiId: val.poiId,
          endpointId: val.endpointId,
          trigger: `${val.trigger.endpointId} ${val.trigger.relOp} ${val.trigger.value}`,
          action: `${val.actions[0].endpointId} = ${val.actions[0].state}`,
          active: val.active,
          ruleId: val.ruleId,
          days: val.days,
          startTime: val.time,
          duration: val.allowedTimePeriod.period,
        }
      })
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Snackbar
            place="tc"
            icon={this.state.snackBar.icon}
            message={this.state.snackBar.message}
            open={this.state.snackBar.status}
            color={this.state.snackBar.color}
            closeNotification={() => this.setState({ snackBar: { status: false } })}
            close
          />
          {
            this.state.modOpen ?
              <ModificationRule showSnackBar={this.showSnackBar} rowDtails={this.state.rowDtails}
                                modOpen={this.state.modOpen}
                                onClose={this.handleModClose}
                                reRender={this.reRender}></ModificationRule> : null
          }
          {
            this.state.delOpen ?
              <DeleteEventRuleForm rowDtails={this.state.rowDtails}
                                   delOpen={this.state.delOpen}
                                   onClose={this.handleDelClose}
                                   showSnackBar={this.showSnackBar}/> : null
          }
          {
            this.state.createEventOpen ?
              <CreateEventRuleForm
                createEventOpen={this.state.createEventOpen}
                onClose={this.handleCreateEventClose}
                showSnackBar={this.showSnackBar}/> : null
          }
          
          <Card>
            <CardHeader color="success">
              <h4 className={this.state.classes.cardTitleWhite}>
                Event Rule
              </h4>
              <p className={this.state.classes.cardCategoryWhite}>
                View Rule
              </p>
            </CardHeader>
            <CardBody>
              <div className="newRuleList">
                <Button
                  onClick={this.handleCreateEventOpen}
                  color="primary"
                  variant="contained">
                  Create EventRule
                </Button>
              </div>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  p: 1,
                  m: 1,
                }}
              >
                <TextField value={this.state.userId} onChange={this.handleUserIdChange}
                           label="UserId" variant="outlined"/>
                <Button onClick={() => this.refreshData(this.state.userId)} color="primary"
                        variant="outlined">
                  Refresh
                </Button>
              </Box>
              <DataGrid
                sx={{ width: '100%', height: 400, textAlign: 'center' }}
                columns={columns}
                rows={rows}
              >
              </DataGrid>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    )
  }
}

export default withStyles(styles)(EventRuleList)
