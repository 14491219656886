// @mui/icons-material

import DevicesIcon from '@mui/icons-material/Devices';
import EndpointsIcon from '@mui/icons-material/DataUsage';
import GavelIcon from '@mui/icons-material/HistoryOutlined';
import SettingsRemoteTwoToneIcon from '@mui/icons-material/Settings';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';

import DeviceList from './views/DeviceList/DeviceList'
import EndpointsList from './views/DeviceList/EndpointsList'
import RuleList from './views/DeviceList/RuleList';
import ControlSwitch from './views/DeviceList/ControlSwitch'
import EventRuleList from './views/DeviceList/EventRuleList'

const dashboardRoutes = [

  {
    path: "/devices",
    name: "IAQ Sensor Devices",
    sideEnable : true,
    rtlName: "قائمة الجدول",
    icon: DevicesIcon,
    component: DeviceList,
    layout: "/admin"
  },
  {
    path: "/endpoints",
    name: "Sensor Endpoints",
    sideEnable : true,
    rtlName: "قائمة الجدول",
    icon: EndpointsIcon,
    component: EndpointsList,
    layout: "/admin"
  },
  {
    path: "/rulelist",
    name: "Rule List",
    sideEnable : true,
    rtlName: "قائمة الجدول",
    icon: GavelIcon,
    component: RuleList,
    layout: "/admin"
  },
  {
    path: "/controlswitch",
    name: "Control Switch",
    sideEnable : true,
    rtlName: "مفتاح التحكم",
    icon: SettingsRemoteTwoToneIcon,
    component: ControlSwitch,
    layout: "/admin"
  },
  {
    path: "/eventrulelist",
    name: "Event RuleList",
    sideEnable : true,
    rtlName: "قائمة الأحداث",
    icon: EventAvailableIcon,
    component: EventRuleList,
    layout: "/admin"
  }
  
];

export default dashboardRoutes;
