import _ from "lodash";

export const getLocalTimeDays = ({time, days}) => {
    console.log('time is call')
    let localDays = days
    const hour = Number(_.chain(time).split(':').head().value())
    const min = Number(_.chain(time).split(':').last().value())
    const date = new Date()
    const tzOffset = date.getTimezoneOffset()
    date.setUTCHours(hour)
    date.setUTCMinutes(min)
    const localDay = date.getDay()
    const utcDay = date.getUTCDay()
    const localHour = date.getHours()
    const localMinutes = date.getMinutes()
    let localTime = _.padStart(`${localHour}`, 2, '0') + ':' + _.padStart(`${localMinutes}`, 2, '0')
    if ((localDay !== utcDay) && (tzOffset > 0)) localDays = leftShift(days, 1)
    if ((localDay !== utcDay) && (tzOffset < 0)) localDays = rightShift(days, 1)
    return {time: localTime, days: localDays}
}

const leftShift = (str, n) => {
    return str.substring(n) + str.substring(0, n);
}

const rightShift = (str, n) => {
    let l = str.length - n;
    return leftShift(str, l);
}

export const getDisplayDays = (days) => {
    const strDays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
    const daysArr = []
    for (let i = 0; i < days.length; i++) {
        daysArr.push(days[i])
    }
    const intDays = daysArr.map((item, index) => ({status: item, displayName: strDays[index]}))
    const display = intDays.filter((day, index) => day.status === '1').map((item) => item.displayName)
    return display
}