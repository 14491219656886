import React from 'react'
import axios from 'axios'
//@mui component
import GridItem from 'components/Grid/GridItem.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'
import Card from 'components/Card/Card.jsx'
import CardHeader from 'components/Card/CardHeader.jsx'
import CardBody from 'components/Card/CardBody.jsx'
import { w3cwebsocket as W3CWebSocket } from 'websocket'
import { FormControl, Select, Typography } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import withStyles from '@mui/styles/withStyles'
import Box from '@mui/material/Box'
import AddAlertIcon from '@mui/icons-material/AddAlert'
import MenuItem from '@mui/material/MenuItem'
import _ from 'lodash'
import Switch from '@mui/material/Switch'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogActions from '@mui/material/DialogActions'
import IconButton from '@mui/material/IconButton'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
// core components
const { REACT_APP_SERVER_URL, REACT_APP_WS_URL } = process.env
const httpUrl = `${REACT_APP_SERVER_URL}`
const wsUrl = `${REACT_APP_WS_URL}`
const styles = {
  cardCategoryWhite: {
    '&,& a,& a:hover,& a:focus': {
      color: 'rgba(255,255,255,.62)',
      margin: '0',
      fontSize: '14px',
      marginTop: '0',
      marginBottom: '0',
    },
    '& a,& a:hover,& a:focus': {
      color: '#FFFFFF',
    },
  },
  cardTitleWhite: {
    color: '#FFFFFF',
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: '\'Roboto\', \'Helvetica\', \'Arial\', sans-serif',
    marginBottom: '3px',
    textDecoration: 'none',
    '& small': {
      color: '#777',
      fontSize: '65%',
      fontWeight: '400',
      lineHeight: '1',
    },
  },
}

class ControlSwitch extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      classes: props.classes,
      tabData: [],
      history: props.history,
      deviceId: '',
      userId: 'boschuser',
      delOpen: false,
      tc: false,
      tce: false,
      action: false,
      endpoitSelect: [],
      deviceSelect: '',
      deviceData: [],
      dropdownSelected: '',
      dropdownOne: [''],
      modOpen: false,
      modName: '',
      modId: '',
      modData: [],
    }
  }
  
  seriesMap = {}
  labelMap = {}
  ws = {}
  tmoutfn = {}
  
  componentWillUnmount() {
    clearInterval(this.tmoutfn)
    this.ws.close()
  }
  
  refreshData(userId) {
    axios.post(`${httpUrl}/data/getEndpointsSnapshot`, {
        userId,
        allControls: true,
      },
      {
        withCredentials: true,
      })
      .then(res => {
        this.setState({ tabData: res.data })
        this.handleSelect()
      })
    this.deviceDataType(userId)
  };
  
  deviceDataType(userId) {
    axios.post(`${httpUrl}/data/getDataSnapshot`, {
        userId,
        allControls: true,
      },
      {
        withCredentials: true,
      })
      .then(res => {
        this.setState({ deviceData: res.data })
      }).catch((error) => {
      let message
      const { response } = error
      if(response) {
        const { data } = response
        if(data) message = data.message
      }
      if(!message) message = error.message
      console.log('error:', message)
      //console.log('message',message)
      this.props.showSnackBar(message, AddAlertIcon, 'error')
      this.props.onClose(true)
    })
  }
  
  connectStream() {
    this.ws = new W3CWebSocket(`${wsUrl}/livestream`)
    var self = this
    this.ws.onopen = () => {
      // on connecting, send userid data interested in
      console.log('connected')
      //This uid not used... for sample only.. quarkifi access uid only used
      self.ws.send(JSON.stringify({ 'userId': 'piNtWTzdwKU1SNZGBHCBbzy3tc42' }))
      this.tmoutfn = setInterval(function () {
        self.ws.send(JSON.stringify({ 'keepAlive': true }))
      }, 10000)
      
    }
    
    this.ws.onmessage = evt => {
      // listen to data sent from the websocket server
      const devData = JSON.parse(evt.data)
      const payload = devData.eventData
      if(devData.eventType === 'ENDPOINT_UPDATE' || devData.eventType === 'ENDPOINT_CONFIG_UPDATE') {
        console.log('event received')
        var curData = self.state.tabData
        for(let i = 0; i < curData.length; i++) {
          if(curData[i].endpointId === payload.endpointId) {
            curData[i].name = payload.name
            curData[i].state = payload.state
            curData[i].timestamp = payload.timestamp
            self.setState({ tabData: curData })
          }
        }
      } else if(devData.eventType === 'DEVICE_DELETE') {
        console.log('event received')
        const curData = self.state.tabData
        for(let i = 0; i < curData.length; i++) {
          if(curData[i].endpointId.contains(payload.deviceId)) {
            curData.splice(i, 1)
            self.setState({ tabData: curData })
          }
        }
      }
      console.log(devData)
    }
    
    this.ws.onclose = () => {
      clearInterval(this.tmoutfn)
      console.log('disconnected')
      // automatically try to reconnect on connection loss
      
    }
    
  }
  
  async componentDidMount() {
    const dropdownData = ['PWR_ON', 'PWR_OFF', 'TEMP_18', 'TEMP_19', 'TEMP_20', 'TEMP_21', 'TEMP_22', 'TEMP_23',
      'TEMP_24', 'TEMP_25', 'TEMP_26', 'TEMP_27', 'TEMP_28', 'FAN_1', 'FAN_2', 'FAN_3', 'FAN_4', 'FAN_5']
    this.setState({ dropdownOne: dropdownData })
    this.deviceDataType(this.state.userId)
    this.refreshData(this.state.userId)
    this.connectStream()
  };
  
  handleOpen = () => {
    this.setState({ delOpen: true })
  }
  
  handleClose = () => {
    this.setState({ delOpen: false })
  }
  
  handleModOpen = () => {
    this.setState({modOpen: true})
  }
  
  handleModClose = () => {
    this.setState({modOpen: false})
  }
  
  handleModOk = () => {
    this.setState({modOpen: false})
    console.log('mod name', this.state.name)
    console.log('id', this.state.modId)
    /*this.modifyData(this.state.modId, {
      id: this.state.modId,
      name:  this.state.name,
    })*/
    //console.log('mods are', this.state.modifiedName, this.state.modifiedFrequency)
    let row = this.state.modData
    console.log('row', row)
    axios.post(`${httpUrl}/data/modEndpoints`,
      {
        userId: row.row.userId,
        endpoints: [
          {
            endpointId: row.row.endpointId,
            name: this.state.name,
            state: row.row.state,
            timestamp: row.row.timestamp,
            userId: row.row.userId
          }
        ]
      },
      {
        withCredentials: true,
      })
      .then((res) => {
        console.log('res.data', res.data)
      
      })
      .catch((error) => {
        let message
        const { response } = error
        if(response) {
          const { data } = response
          if(data) message = data.message
        }
        if(!message) message = error.message
        console.log('error:', message)
        //console.log('message',message)
        this.props.showSnackBar(message, AddAlertIcon, 'error')
        this.props.onClose(true)
      })
  }
  
  handleUserIdChange = (e) => {
    this.setState({ userId: e.target.value })
  }
  
  handleNameChange = (e) => {
    this.setState({name: e.target.value})
  }
  
  handleSelect = () => {
    const pushEndpoint = []
    this.state.tabData.forEach((val) => {
      pushEndpoint.push(val.endpointId.split('.')[0])
    })
    this.setState({ endpoitSelect: pushEndpoint })
  }
  
  handleCreateAction = (row) => {
    let endpoint = row.endpointId
    const deviceId = endpoint.split('.')
    axios.post(`${httpUrl}/data/modEndpoints`,
      {
        userId: row.userId,
        deviceId: deviceId[0],
        endpoints: [
          {
            endpointId: row.endpointId,
            name: row.name,
            poiId: row.poiId,
            state: row.state === '1' ? '0' : '1',
            storeId: row.storeId,
            timestamp: row.timestamp,
            userId: row.userId
          }
        ]
      },
      {
        withCredentials: true,
      })
      .then((res) => {
        console.log('res.data', res.data)
        
      })
      .catch((error) => {
        let message
        const { response } = error
        if(response) {
          const { data } = response
          if(data) message = data.message
        }
        if(!message) message = error.message
        console.log('error:', message)
        //console.log('message',message)
        this.props.showSnackBar(message, AddAlertIcon, 'error')
        this.props.onClose(true)
      })
  }
  
  handleCreateDropdownFirst = (e, row) => {
    let selected = e.target.value
    this.setState({ dropdownSelected: selected })
    let endpoint = row.endpointId
    const deviceId = endpoint.split('.')
    axios.post(`${httpUrl}/data/modEndpoints`,
      {
        userId: row.userId,
        deviceId: deviceId[0],
        endpoints: [
          {
            endpointId: row.endpointId,
            name: row.name,
            poiId: row.poiId,
            state: selected,
            storeId: row.storeId,
            timestamp: row.timestamp,
            userId: row.userId
          }
        ]
      },
      {
        withCredentials: true,
      })
      .then((res) => {
        console.log('res.data!!!', res.data)
        
      })
      .catch((error) => {
        let message
        const { response } = error
        if(response) {
          const { data } = response
          if(data) message = data.message
        }
        if(!message) message = error.message
        console.log('error:', message)
        //console.log('message',message)
        this.props.showSnackBar(message, AddAlertIcon, 'error')
        this.props.onClose(true)
      })
  }
  
  render() {
    let columns = [
      { headerName: 'Name', field: 'name', width: 200 },
      { headerName: 'Id', field: 'idCol', width: 200 },
      {
        headerName: 'Value', field: 'value', width: 200,
        renderCell: (rowData) => {
          return (
            <>
              {
                rowData.row.deviceDataType ?
                  <FormControl>
                    <Select
                      label="TEMP"
                      sx={{ padding: 2, height: 4 }}
                      defaultValue
                      displayEmpty
                      value={rowData.row.state}
                      onChange={(e) => this.handleCreateDropdownFirst(e, rowData.row)}
                    >
                      {
                        this.state.dropdownOne.map((dOne, dindex) => {
                          return (
                            <MenuItem value={dOne} key={dOne}>{dOne}</MenuItem>
                          )
                        })
                      }
                    </Select>
                  </FormControl> :
                  <>
                    <Typography>Off</Typography>
                    <Switch name="action"
                            checked={rowData.row.state === '1'}
                            onChange={() => this.handleCreateAction(rowData.row)}
                            margin="dense"
                            id="action"
                            label="action"
                            color="success"/>
                    <Typography>On</Typography>
                  </>
              }
            </>
          )
        }
      },
      {
        headerName: 'Last Updated At', field: 'lastupdatedat', width: 400,
        renderCell: (rowData) => <span>
                {new Date(rowData.row.timestamp * 1000).toString()}
                </span>,
      },{
        headerName: 'Action', field: 'action', type: 'action', width: 100,
        renderCell:(params)=>{
          console.log('params!!!', params)
          return <>
            <IconButton onClick={console.log('Open')} size="large">
              <EditIcon
                onClick={(event) => this.setState({
                  //deviceId: params.row.deviceId,
                  name: params.row.name,
                  modId: params.row.id,
                  modData: params,
                  modOpen: true,
                })
                }/>
            </IconButton>
          </>;
      
        }
      }]
    let rows = this.state.tabData.map((param, index) => {
      const endId = _.first(param.endpointId.split('.'))
      let deviceItem = _.find(this.state.deviceData, { deviceType: 'IRB', deviceId: endId }) //find IRB then match deviceType or endpointId
      //console.log('param', param)
      let _row = {
        id: index,
        name: param.name,
        idCol: param.endpointId,
        value: 'kk',
        lastupdatedat: '22/5/3',
        timestamp: param.timestamp,
        state: param.state,
        endpointId: param.endpointId,
        userId: param.userId,
        deviceDataType: deviceItem ? true : false,
        IRBDropdownDefault: param.state,
        //dropdownSelected: this.state.dropdownSelected,
      }
      return _row
    })
    return (
      <>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="success">
              <h4 className={this.state.classes.cardTitleWhite}>Control Endpoints</h4>
            </CardHeader>
            <CardBody>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  p: 1,
                  m: 1,
                }}
              >
                <TextField value={this.state.userId} onChange={this.handleUserIdChange}
                           label="UserId" variant="outlined"/>
                <Button onClick={() => this.refreshData(this.state.userId)} color="primary"
                        variant="outlined">
                  Refresh
                </Button>
              </Box>
              <DataGrid
                sx={{ width: '100%', height: 600, textAlign: 'center' }}
                columns={columns}
                rows={rows}
              >
              </DataGrid>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
        <Dialog
          open={this.state.modOpen}
          onClose={this.handleModClose}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'>
          <DialogTitle id='alert-dialog-title'>{'Control Switch Edit'}</DialogTitle>
          <DialogContent>
            <DialogContentText id='alert-dialog-description'>
              Update Following fields
            </DialogContentText>
            <TextField value={this.state.name} onChange={this.handleNameChange}
                       label='Name' variant='outlined' />
            {/*<TextField  value={this.state.modifiedFrequency} onChange={this.handleFrequencyChange}
                        label='Polling Frequency' variant='outlined' />*/}
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleModClose} color='primary'>
              Cancel
            </Button>
            <Button onClick={this.handleModOk} color='primary'>
              OK
            </Button>
    
          </DialogActions>
        </Dialog>
        </>
    )
  }
}

export default withStyles(styles)(ControlSwitch)
