import React from "react";
import axios from "axios";
//@mui component
import _ from "lodash";
import GridItem from 'components/Grid/GridItem.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'
import ButtonGroup from "@mui/material/ButtonGroup";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {TimePicker} from "@mui/x-date-pickers/TimePicker";
import {FormControlLabel, FormGroup, FormLabel} from "@mui/material";
import Switch from "@mui/material/Switch";
import Divider from "@mui/material/Divider";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Grid from '@mui/material/Grid';
import AddAlertIcon from '@mui/icons-material/AddAlert';
import {TextField} from '@mui/material';
import Button from '@mui/material/Button';

const {REACT_APP_SERVER_URL} = process.env;
const httpUrl = `${REACT_APP_SERVER_URL}`

class ModificationRule extends React.Component {
    constructor(props) {
        super(props);
        const days = this.props.rowDtails.days  // user created days at time of ruleList
        this.state = {
            classes: props.classes,
            tabData: [],
            history: props.history,
            deviceId: "",
            delOpen: false,
            modOpen: false,
            tc: false,
            poiId: props.rowDtails.poiId,
            userId: props.rowDtails.userId,
            storeId: props.rowDtails.storeId,
            endpointId: props.rowDtails.endpointId,
            ruleId: props.rowDtails.ruleId,
            time: (() => {
                const date = new Date();
                date.setHours.apply(date, this.props.rowDtails.time.split(":"))
                return date;
            })(),
            days: "",
            period: this.props.rowDtails.period,
            repeat: this.props.rowDtails.repeat,
            status: this.props.rowDtails.active,
            action: this.props.rowDtails.state,   //user view is "ACTION" but in code we used "STATE"
            localDays: [],
            buttonColor: 'rgb(' + (76) + ',' + (175) + ',' + (80) + ')',
            tce: false,
            snackSuccess: false,
            weekButtons: this.weekButtons.map(item => {
                return days.includes(item.label) ? {...item, status: 1} : item  //here we take weekDays put in updated week days
            }),
            buttonBackgorunThem: '#616161'
        }
        console.log(days, this.state.weekButtons, " ", this.state)
    }

    handleModClose = () => {
        this.props.onClose();
    }

    handleModOk = () => {
        this.modifyData({
            poiId: this.state.period, time: this.state.time, days: this.state.weekButtons, repeat: this.state.repeat,
            action: this.state.action, status: this.state.status
        })
    }

    async modifyData(modifiedData) {
        console.log("come in modification " + JSON.stringify(modifiedData))
        /*DAYS MODIFICATION START*/
        const daysNumber = modifiedData.days
        const dayNumberStatus = []
        daysNumber.forEach((day) => {
            dayNumberStatus.push(day.status)
        })
        /*DAYS MODIFICATION END*/
        /* TIME MODIFICATION START */
        const LocalTime = modifiedData.time
        /*defult time take start*/
        if (LocalTime.$d == undefined) {  //no time is selected so if condition is true
            const houre = LocalTime.getHours()
            const minute = LocalTime.getMinutes()
            const hh = houre < 10 ? `0${houre}` : houre
            const mm = minute < 10 ? `0${minute}` : minute
            var time = `${hh}:${mm}`
        } else {
            const houre = LocalTime.$H
            const minute = LocalTime.$m
            const hh = houre < 10 ? `0${houre}` : houre
            const mm = minute < 10 ? `0${minute}` : minute
            var time = `${hh}:${mm}`
        }
        /*defult time take end*/
        /*TIME MODIFICATION END*/
        const {time: utcTime, days: utcDays} = this.getUTCTimeDays({time, days: dayNumberStatus.join("")})
        this.updateApiCall(utcTime, utcDays, modifiedData)
    };

    updateApiCall(time, days, modifiedData) {
        try {
            axios.put(`${httpUrl}/data/schedulerule`,
                {
                    endpointId: this.state.endpointId,
                    poiId: this.state.poiId,
                    storeId: this.state.storeId,
                    userId: this.state.userId,
                    ruleId: this.state.ruleId,
                    time: time,
                    days: days,
                    period: parseInt(modifiedData.poiId),
                    repeat: modifiedData.repeat,
                    state: modifiedData.action,
                    active: modifiedData.status,
                },
                {
                    withCredentials: true,
                },).then(() => {
                this.props.showSnackBar("Rule Updated Successfully!!", AddAlertIcon, "success")
                this.props.onClose(true);

            }).catch((error)=>{
                let message
                const { response } = error
                if (response) {
                    const { data } = response
                    if (data) message = data.message
                }
                if(!message) message = error.message
                console.log('error:', message)
                //console.log('message',message)
                this.props.showSnackBar(message, AddAlertIcon, "error")
                this.props.onClose(true)
            })
        } catch (e) {
            this.props.showSnackBar("Trouble To Rule Edit!!", AddAlertIcon, "warning")
        }
    }

    handleDaysChange = (e) => {
        alert(e.target.value)
        this.setState({days: e.target.value})
    }

    weekButtons = [{
        label: 'Sun', status: 0
    }, {
        label: 'Mon', status: 0
    }, {
        label: 'Tue', status: 0
    }, {
        label: 'Wed', status: 0
    }, {
        label: 'Thu', status: 0
    }, {
        label: 'Fri', status: 0
    }, {
        label: 'Sat', status: 0
    }];

    handleWeeks = (week) => {
        this.state.weekButtons[week].status = this.state.weekButtons[week].status === 0 ? 1 : 0;
        this.setState({weekButtons: this.state.weekButtons})
    }

    handleCreateTimeChange = (time) => {
        this.setState({time: time})
    }

    handleCreatePeriodChange = (peId) => {
        this.setState({period: peId.target.value})
    }

    handleCraeteRepeat = (e) => {
        this.setState({repeat: e.target.checked})
    }

    handleCreateAction = (e) => {
        const actionBoolean = e.target.checked == true ? "1" : "0"
        this.setState({action: actionBoolean})
    }

    handleCreateStatus = (e) => {
        const statusBoolean = e.target.checked == true ? true : false
        this.setState({status: statusBoolean})
    }

    getUTCTimeDays = ({time, days}) => {
        let utcDays = days
        const hour = Number(_.chain(time).split(':').head().value())
        const min = Number(_.chain(time).split(':').last().value())
        const date = new Date()
        const tzOffset = date.getTimezoneOffset()
        const localDay = date.getDay()
        date.setHours(hour)
        date.setMinutes(min)
        const utcDay = date.getUTCDay()
        const utcHour = date.getUTCHours()
        const utcMinutes = date.getUTCMinutes()
        let utcTime = _.padStart(`${utcHour}`, 2, '0') + ':' + _.padStart(`${utcMinutes}`, 2, '0')
        if ((localDay !== utcDay) && (tzOffset > 0)) utcDays = this.rightShift(days, 1)
        if ((localDay !== utcDay) && (tzOffset < 0)) utcDays = this.leftShift(days, 1)
        return {time: utcTime, days: utcDays}
    }

    leftShift(str, n) {
        return str.substring(n) + str.substring(0, n);
    }

    rightShift(str, n) {
        let l = str.length - n;
        return this.leftShift(str, l);
    }

    handleCreateUserIdChange = (uId) => {
        this.setState({userId: uId.target.value})
    }

    handleCreateStoreIdChange = (sId) => {
        this.setState({storeId: sId.target.value})
    }

    handleCreatePoiIdChange = (pId) => {
        this.setState({poiId: pId.target.value})
    }

    handleCreateEndpointIdChange = (eId) => {
        this.setState({endpointId: eId.target.value})
    }

    render() {
        return (
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <Dialog
                        open={true}
                        onClose={this.handleModClose}
                        aria-labelledby='alert-dialog-title'
                        aria-describedby='alert-dialog-description'>
                        <DialogTitle id='alert-dialog-title'>{'Device rule Edit'}</DialogTitle>
                        <DialogContent>
                            <TextField
                                disabled
                                name="userId"
                                onChange={this.handleCreateUserIdChange}
                                value={this.state.userId}
                                autoFocus
                                margin="dense"
                                id="userId"
                                label="userId"
                                type="text"
                                variant="standard"
                                fullWidth
                            />
                            <TextField
                                disabled
                                name="storeId"
                                onChange={this.handleCreateStoreIdChange}
                                value={this.state.storeId}
                                margin="dense"
                                id="storeId"
                                label="storeId"
                                type="text"
                                variant="standard"
                                fullWidth
                            />
                            <TextField
                                disabled
                                name="poiId"
                                onChange={this.handleCreatePoiIdChange}
                                value={this.state.poiId}
                                margin="dense"
                                id="poiId"
                                label="poiId"
                                type="text"
                                variant="standard"
                                fullWidth
                            />
                            <TextField
                                disabled
                                name="endpointId"
                                onChange={this.handleCreateEndpointIdChange}
                                value={this.state.endpointId}
                                margin="dense"
                                id="endpointId"
                                label="endpointId"
                                type="text"
                                variant="standard"
                                fullWidth
                            />
                            <FormLabel sx={{
                                '& > :not(style)': {m: 1, width: '48ch'},
                            }}>
                                <div className="weekDays">week days</div>
                                <TextField
                                    value={this.state.days}
                                    onChange={this.handleDaysChange}
                                    variant='outlined'
                                    InputProps={{
                                        endAdornment: <ButtonGroup sx={{height: 50, marginLeft: -1.5}}
                                                                   className="buttonSpace"
                                                                   edge="start"
                                                                   variant="contained"
                                        >
                                            {this.state.weekButtons.map((item, index) => (<Button
                                                sx={{
                                                    backgroundColor: this.state.buttonBackgorunThem, '&:hover': {
                                                        background: "rgb(7, 177, 77, 0.42)",
                                                    }
                                                }}
                                                style={{backgroundColor: item.status === 1 ? this.state.buttonColor : null}}
                                                onClick={event => this.handleWeeks(index)}>{item.label}</Button>))}
                                        </ButtonGroup>
                                    }}>
                                </TextField>
                            </FormLabel>
                            <LocalizationProvider dateAdapter={AdapterDayjs} disableEnforceFocus>
                                <TimePicker
                                    label="time"
                                    name="time"
                                    value={this.state.time}
                                    onChange={this.handleCreateTimeChange}
                                    id="time"
                                    type="text"
                                    fullWidth
                                    renderInput={(params) => <TextField {...params} />}
                                    disableEnforceFocus
                                />
                            </LocalizationProvider>
                            <TextField
                                name="period"
                                onChange={this.handleCreatePeriodChange}
                                value={this.state.period}
                                margin="dense"
                                id="period"
                                label="period"
                                type="text"
                                variant="standard"
                                fullWidth
                            />
                            <FormLabel>repeat</FormLabel>
                            <FormGroup>
                                <Grid component="label" container alignItems="center" spacing={1}>
                                    <Grid item>No</Grid>
                                    <Grid item>
                                        <FormControlLabel
                                            control={<Switch name="repeat"
                                                             checked={this.state.repeat == true ? true : false}
                                                             onChange={this.handleCraeteRepeat}
                                                             value={this.state.repeat}
                                                             margin="dense"
                                                             id="repeat"
                                                             label="repeat"
                                                             fullWidth
                                                             color="success"/>}
                                        />
                                    </Grid>
                                    <Grid item>Yes</Grid>
                                </Grid>
                                <Divider/>
                                <FormLabel>action</FormLabel>
                                <Grid component="label" container alignItems="center" spacing={1}>
                                    <Grid item>Off</Grid>
                                    <Grid item>
                                        <FormControlLabel
                                            control={<Switch name="action"
                                                             checked={this.state.action == "1" ? true : false}
                                                             onChange={this.handleCreateAction}
                                                             value={this.state.action}
                                                             margin="dense"
                                                             id="action"
                                                             label="action"
                                                             fullWidth
                                                             color="success"/>
                                            }
                                        />
                                    </Grid>
                                    <Grid item>On</Grid>
                                </Grid>
                                <Divider/>
                                <FormLabel>active</FormLabel>
                                <Grid component="label" container alignItems="center" spacing={1}>
                                    <Grid item>inactive</Grid>
                                    <Grid item>
                                        <FormControlLabel
                                            control={<Switch name="status"
                                                             checked={this.state.status == true ? true : false}
                                                             onChange={this.handleCreateStatus}
                                                             value={this.state.status}
                                                             margin="dense"
                                                             id="status"
                                                             label="status"
                                                             fullWidth
                                                             color="success"/>
                                            }
                                        />
                                    </Grid>
                                    <Grid item>active</Grid>
                                </Grid>
                            </FormGroup>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.handleModClose} color='primary'>
                                Cancel
                            </Button>
                            <Button onClick={this.handleModOk} color='primary'>
                                OK
                            </Button>
                        </DialogActions>
                    </Dialog>
                </GridItem>
            </GridContainer>
        )
    }
}

export default ModificationRule